import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const selectRetailState = (store: IStore) => store.retail;

export const selectView = createSelector(
    selectRetailState,
    state => state.retailViewState
);

export const selectSummary = createSelector(
    selectRetailState,
    state => state.summary
);

export const selectCardErrorMessage = createSelector(
    selectRetailState,
    state => state.cardErrorMessage
);

export const selectCardRecordState = createSelector(
    selectRetailState,
    state => state.cardRecord
);

export const selectSubmittingViewState = createSelector(
    selectRetailState,
    state => state.submittingViewState
);
