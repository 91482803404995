import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { IStore } from "src/app/storage/store";
import * as RetailSelectors from "src/app/storage/retail/retail.selectors";
import * as RetailActions from "src/app/storage/retail/retail.actions";
import { CardRecord, PayWithCard, RetailViewState } from "src/app/storage/retail/retail.state";

@Component({
  selector: "retail-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"]
})
export class PaymentComponent implements OnInit {

    retailView$ = this.store.select(RetailSelectors.selectView);
    retailSummary$ = this.store.select(RetailSelectors.selectSummary);
    cardErrorMessage$ = this.store.select(RetailSelectors.selectCardErrorMessage);
    cardRecord$ = this.store.select(RetailSelectors.selectCardRecordState);
    submittingViewState$ = this.store.select(RetailSelectors.selectSubmittingViewState);

    constructor(
        private store: Store<IStore>,
        private title: Title,
    ) {}

    RetailView = RetailViewState;

    ngOnInit(): void {

        this.title.setTitle("Retail Payment");
        this.store.dispatch(RetailActions.InitializeRetailAction());
    }

    cardRecordChange(cardRecord: CardRecord): void {

        this.store.dispatch(RetailActions.CardRecordChangeAction({ cardRecord }));
    }

    payWithCreditCard(data: PayWithCard): void {

        this.store.dispatch(RetailActions.PayWithCreditCardAction({ data }));
    }

    payClover(): void {

        this.store.dispatch(RetailActions.PayCloverAction());
    }

}

