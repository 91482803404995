<div class="content">
    <h1 class="content-head">Confirm order and pay</h1>

    <payment-form
        [summary]="summary"
        [cardErrorMessage]="cardErrorMessage"
        [eCheckErrorMessage]="eCheckErrorMessage"
        [eCheckRecord]="eCheckRecord"
        (eCheckRecordChange)="eCheckRecordChange.emit($event)"
        [cardRecord]="cardRecord"
        (cardRecordChange)="cardRecordChange.emit($event)"
        (payWithCreditCard)="payWithCreditCard.emit($event)"
        (payWithECheck)="payWithECheck.emit()"
        (payWithPaymentSystems)="payWithPaymentSystems.emit($event)"
        (cancelPayment)="cancelPayment.emit($event)">
    </payment-form>
</div>

<div *ngIf="submittingViewState == SubmittingViewStates.SUBMITTING"
    class="processing-overlay">
    <div class="processing-overlay__progress">
        <div class="animation-load">
            <div class="animation-load__item"></div>
            <div class="animation-load__item"></div>
            <div class="animation-load__item"></div>
            <div class="animation-load__item"></div>
            <div class="animation-load__item"></div>
        </div>
        <span>Payment Processing</span>
    </div>
</div>

<div *ngIf="submittingViewState == SubmittingViewStates.SUCCESS"
    class="processing-overlay">
    <div class="processing-overlay__successfull">
        <i class="fal fa-check-circle"></i>
        <span>Payment Success!!</span>
        <p>
            Please wait a moment we will redirect you to a confirmation page
        </p>
    </div>
</div>
