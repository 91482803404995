import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { IMaskModule } from "angular-imask";

import { AppRoutingModule } from "./app-routing.module";
import { EPayApiModule } from "Portal/epay-api.module";
import { SharedModule } from "./shared/shared.module";

import { environment } from "src/environments/environment";
import { ReducerConfiguration, ReducerMap } from "./storage/reducer-configuration";
import { EffectsConfiguration } from "./storage/effects-configuration";

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { PaySectionComponent } from "./pages/ecom/pay-section/pay-section.component";
import { PaymentFormComponent } from "./pages/ecom/pay-section/payment-form/payment-form.component";
import { SummaryComponent } from "./pages/ecom/pay-section/summary/summary.component";
import { RiskDeclinedComponent } from "./pages/ecom/risk-declined/risk-declined.component";
import { CreditCardFormComponent } from "./pages/ecom/pay-section/credit-card-form/credit-card-form.component";
import { ECheckFormComponent } from "./pages/ecom/pay-section/echeck-form/echeck-form.component";
import { PaymentPageComponent } from "./pages/ecom/payment-page/payment-page.component";
import { TermsAndConditionsPageComponent } from "./pages/terms-and-conditions/terms-and-conditions-page.component";
import { PrivacyPolicyPageComponent } from "./pages/privacy-policy/privacy-policy-page.component";
import { PaymentSectionComponent } from "./pages/retail/pay-section/payment-section.component";
import { CloverComponent } from "./pages/retail/pay-section/clover/clover.component";
import { PaymentComponent } from "./pages/retail/payment-page/payment.component";
import { MotoComponent } from "./pages/retail/pay-section/moto/moto.component";
import { RetailCreditCardFormComponent } from "./pages/retail/pay-section/moto/credit-card-form/credit-card-form.component";
import { ReceiptComponent } from "./pages/receipt/receipt.component";
import { MatSelectSafariFix } from "./mat-select-safari-fix.directive";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        StoreModule.forRoot(ReducerMap, ReducerConfiguration),
        EffectsModule.forRoot(EffectsConfiguration),
        StoreDevtoolsModule.instrument({
            maxAge: 50,
            logOnly: environment.production,
        }),
        BrowserAnimationsModule,
        HttpClientModule,
        EPayApiModule.forRoot({ rootUrl: environment.apiRootUrl }),
        IMaskModule,
        SharedModule,
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        PaymentPageComponent,
        TermsAndConditionsPageComponent,
        PrivacyPolicyPageComponent,
        PaySectionComponent,
        PaymentFormComponent,
        SummaryComponent,
        RiskDeclinedComponent,
        CreditCardFormComponent,
        ECheckFormComponent,
        PaymentComponent,
        ReceiptComponent,
        PaymentSectionComponent,
        RetailCreditCardFormComponent,
        CloverComponent,
        MotoComponent,
        CloverComponent,
        MatSelectSafariFix,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
