/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { EPayApiConfiguration as __Configuration } from '../epay-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CheckoutSummaryModel } from '../models/checkout-summary-model';
import { CheckoutSummaryRequest } from '../models/checkout-summary-request';
import { PaymentSubmitResponse } from '../models/payment-submit-response';
import { PaymentCreditCardSubmitModel } from '../models/payment-credit-card-submit-model';
import { PaymentECheckSubmitModel } from '../models/payment-echeck-submit-model';
import { PaymentSelectionModel } from '../models/payment-selection-model';
import { ReceiptModel } from '../models/receipt-model';
import { SendRecepitModel } from '../models/send-recepit-model';
@Injectable({
  providedIn: 'root',
})
class CheckoutService extends __BaseService {
  static readonly checkoutGetCheckoutSummaryModelPath = '/getcheckoutsummary';
  static readonly checkoutPayCreditCardPath = '/checkout/paycreditcard';
  static readonly checkoutPayEcheckPath = '/checkout/payecheck';
  static readonly checkoutGetGeneratedPaymentUrlPath = '/gettestpaymenturl';
  static readonly checkoutGetAbaDataPath = '/aba/{routingNumber}';
  static readonly checkoutGetPaymentSelectionModelPath = '/getpaymentselection';
  static readonly checkoutReceiptPath = '/receipt';
  static readonly checkoutSendReceiptPath = '/sendreceipt';
  static readonly checkoutCancelPaymentPath = '/checkout/cancelpayment';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param request undefined
   */
  checkoutGetCheckoutSummaryModelResponse(request: CheckoutSummaryRequest): __Observable<__StrictHttpResponse<CheckoutSummaryModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/getcheckoutsummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CheckoutSummaryModel>;
      })
    );
  }
  /**
   * @param request undefined
   */
  checkoutGetCheckoutSummaryModel(request: CheckoutSummaryRequest): __Observable<CheckoutSummaryModel> {
    return this.checkoutGetCheckoutSummaryModelResponse(request).pipe(
      __map(_r => _r.body as CheckoutSummaryModel)
    );
  }

  /**
   * @param model undefined
   */
  checkoutPayCreditCardResponse(model: PaymentCreditCardSubmitModel): __Observable<__StrictHttpResponse<PaymentSubmitResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/checkout/paycreditcard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentSubmitResponse>;
      })
    );
  }
  /**
   * @param model undefined
   */
  checkoutPayCreditCard(model: PaymentCreditCardSubmitModel): __Observable<PaymentSubmitResponse> {
    return this.checkoutPayCreditCardResponse(model).pipe(
      __map(_r => _r.body as PaymentSubmitResponse)
    );
  }

  /**
   * @param model undefined
   */
  checkoutPayEcheckResponse(model: PaymentECheckSubmitModel): __Observable<__StrictHttpResponse<PaymentSubmitResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/checkout/payecheck`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentSubmitResponse>;
      })
    );
  }
  /**
   * @param model undefined
   */
  checkoutPayEcheck(model: PaymentECheckSubmitModel): __Observable<PaymentSubmitResponse> {
    return this.checkoutPayEcheckResponse(model).pipe(
      __map(_r => _r.body as PaymentSubmitResponse)
    );
  }

  /**
   * @param params The `CheckoutService.CheckoutGetGeneratedPaymentUrlParams` containing the following parameters:
   *
   * - `amount`:
   *
   * - `officeId`:
   *
   * - `allowedPaymentCode`:
   */
  checkoutGetGeneratedPaymentUrlResponse(params: CheckoutService.CheckoutGetGeneratedPaymentUrlParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.amount != null) __params = __params.set('amount', params.amount.toString());
    if (params.officeId != null) __params = __params.set('officeId', params.officeId.toString());
    if (params.allowedPaymentCode != null) __params = __params.set('allowedPaymentCode', params.allowedPaymentCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/gettestpaymenturl`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `CheckoutService.CheckoutGetGeneratedPaymentUrlParams` containing the following parameters:
   *
   * - `amount`:
   *
   * - `officeId`:
   *
   * - `allowedPaymentCode`:
   */
  checkoutGetGeneratedPaymentUrl(params: CheckoutService.CheckoutGetGeneratedPaymentUrlParams): __Observable<Blob> {
    return this.checkoutGetGeneratedPaymentUrlResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param routingNumber undefined
   */
  checkoutGetAbaDataResponse(routingNumber: number): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/aba/${encodeURIComponent(routingNumber)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param routingNumber undefined
   */
  checkoutGetAbaData(routingNumber: number): __Observable<Blob> {
    return this.checkoutGetAbaDataResponse(routingNumber).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param trackingNumber undefined
   */
  checkoutGetPaymentSelectionModelResponse(trackingNumber?: null | string): __Observable<__StrictHttpResponse<PaymentSelectionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (trackingNumber != null) __params = __params.set('trackingNumber', trackingNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/getpaymentselection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentSelectionModel>;
      })
    );
  }
  /**
   * @param trackingNumber undefined
   */
  checkoutGetPaymentSelectionModel(trackingNumber?: null | string): __Observable<PaymentSelectionModel> {
    return this.checkoutGetPaymentSelectionModelResponse(trackingNumber).pipe(
      __map(_r => _r.body as PaymentSelectionModel)
    );
  }

  /**
   * @param trackingNumber undefined
   */
  checkoutReceiptResponse(trackingNumber?: null | string): __Observable<__StrictHttpResponse<ReceiptModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (trackingNumber != null) __params = __params.set('trackingNumber', trackingNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/receipt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReceiptModel>;
      })
    );
  }
  /**
   * @param trackingNumber undefined
   */
  checkoutReceipt(trackingNumber?: null | string): __Observable<ReceiptModel> {
    return this.checkoutReceiptResponse(trackingNumber).pipe(
      __map(_r => _r.body as ReceiptModel)
    );
  }

  /**
   * @param model undefined
   */
  checkoutSendReceiptResponse(model: SendRecepitModel): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/sendreceipt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param model undefined
   */
  checkoutSendReceipt(model: SendRecepitModel): __Observable<Blob> {
    return this.checkoutSendReceiptResponse(model).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param model undefined
   */
  checkoutCacelPaymentResponse(trackingNumber: string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (trackingNumber != null) __params = __params.set('trackingNumber', trackingNumber.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/checkout/cancelpayment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param model undefined
   */
  checkoutCacelPayment(trackingNumber: string): __Observable<Blob> {
    return this.checkoutCacelPaymentResponse(trackingNumber).pipe(
      __map(_r => _r.body as Blob)
    );
  }


}

module CheckoutService {

  /**
   * Parameters for CheckoutGetGeneratedPaymentUrl
   */
  export interface CheckoutGetGeneratedPaymentUrlParams {
    amount?: number;
    officeId?: 'VRR' | 'ODLR';
    allowedPaymentCode?: null | string;
  }
}

export { CheckoutService }
