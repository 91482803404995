import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CheckoutService } from "Portal/services/checkout.service";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { EmailModalComponent } from "src/app/shared/email-modal/email-modal.component";
import * as ReceiptActions from "src/app/storage/receipt/receipt.actions";
import { ViewState } from "./receipt.state";

@Injectable()
export class ReceiptEffects {

    constructor(
        private actions: Actions,
        private matDialog: MatDialog,
        private checkoutService: CheckoutService,
    ) {}

    initializeRetail$ = createEffect(() => this.actions.pipe(
        ofType(ReceiptActions.InitializeReceiptAction),
        switchMap(action => this.checkoutService.checkoutReceipt(action.trackingNumber)),
        map(receipt => ReceiptActions.ExtendStateAction({ newState: { receipt, viewState: ViewState.RECEIPT } }))
    ));


    sendReceiptEmail$ = createEffect(() => this.actions.pipe(
        ofType(ReceiptActions.SendReceiptEmailAction),
        switchMap(action => {

            const modalRef = this.matDialog.open(EmailModalComponent, {
                minWidth: "300px",
                width: "30vw",
            });

            return modalRef.afterClosed().pipe(
                filter(i => !!i),
                switchMap(email => this.checkoutService.checkoutSendReceipt({ trackingNumber: action.trackingNumber, email })),
                tap(() => modalRef.close()),
            );
        }),
    ), { dispatch: false });
}
