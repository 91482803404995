<div class="content content_allowed">
    <ng-container *ngIf="(retailSummary$ | async) as summary">
        <div class="payment-info">
            <div class="row">
                <div class="col-4">
                    <div class="payment-info__head">Reference ID</div>
                </div>
                <div class="col-4">
                    <div class="payment-info__head">Tech ID</div>
                </div>
                <div class="col-4">
                    <div class="payment-info__head">Customer Name</div>
                </div>
                <div class="col-4">
                    <div class="payment-info__head">Amount Due</div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="payment-info__value">{{ summary.referenceId || '-' }}</div>
                </div>
                <div class="col-4">
                    <div class="payment-info__value">{{ summary.retailPaymentData?.techId || '-' }}</div>
                </div>
                <div class="col-4">
                    <div class="payment-info__value">{{ summary.customerName || '-' }}</div>
                </div>
                <div class="col-4">
                    <div class="payment-info__value">{{ summary.amountDue | currency }}</div>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="payment-content">
        <ng-container *ngIf="(retailView$ | async) == RetailView.LOADING then loading"></ng-container>
        <ng-container *ngIf="(retailView$ | async) == RetailView.CARD then card"></ng-container>
    </div>
</div>

<ng-template #loading>
    <p>loading</p>
</ng-template>

<ng-template #card>
    <retail-payment-section
        [cardErrorMessage]="cardErrorMessage$ | async"
        [submittingViewState]="submittingViewState$ | async"
        [cardRecord]="cardRecord$ | async"
        (cardRecordChange)="cardRecordChange($event)"
        (payWithCreditCard)="payWithCreditCard($event)"
        (payClover)="payClover()"
        [summary]="retailSummary$ | async">
    </retail-payment-section>
</ng-template>
