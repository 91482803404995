<div [formGroup]="cardForm"
    class="section section-mb-0">

    <div class="section_title">
        <h3>Card Info</h3>
    </div>

    <div class="section_item-mb">
        <div id="card-number"></div>
        <div *ngIf="hasError(CARD_FIELDS.CARD_NUMBER) && touched(CARD_FIELDS.CARD_NUMBER)"
            class="card-error">
            {{ getError(CARD_FIELDS.CARD_NUMBER) }}
        </div>
    </div>

    <div class="third-width">
        <div>
            <div id="card-date"></div>
            <div *ngIf="hasError(CARD_FIELDS.CARD_DATE) && touched(CARD_FIELDS.CARD_DATE)"
                class="card-error">
                {{ getError(CARD_FIELDS.CARD_DATE) }}
            </div>
        </div>
        <div>
            <div id="card-cvv"></div>
            <div *ngIf="hasError(CARD_FIELDS.CARD_CVV) && touched(CARD_FIELDS.CARD_CVV)"
                class="card-error">
                {{ getError(CARD_FIELDS.CARD_CVV) }}
            </div>
        </div>
        <div>
            <div id="card-postal-code"></div>
            <div *ngIf="hasError(CARD_FIELDS.CARD_POSTAL_CODE) && touched(CARD_FIELDS.CARD_POSTAL_CODE)"
                class="card-error">
                {{ getError(CARD_FIELDS.CARD_POSTAL_CODE) }}
            </div>
        </div>
    </div>

    <div class="full-width">
        <mat-form-field appearance="outline">
            <mat-label>Cardholder's Name</mat-label>
            <input matInput
                type="text"
                formControlName="cardHolderName">
            <mat-error *ngIf="cardForm.get('cardHolderName').hasError('maxlength')">Should be less than 100 characters</mat-error>
            <mat-error *ngIf="cardForm.get('cardHolderName').hasError('required')">Field is required</mat-error>
        </mat-form-field>
    </div>

    <div class="section__desc section__desc-transparent">
        <div class="section__desc_footer">
            An additional service fee of 2.3% will be added to card payments.
        </div>
        <div class="section__desc_footer">
            This charge and the associated service fee are being processed by Motor Vehicle Software Corporation and will appear on your cardholder statement as two separate charges:
            <br>MVD Fee - <strong>{{ agencyFeeDescriptor }}</strong> 406-285-8250 MT.
            <br>Service Fee - <strong>{{ serviceFeeDescriptor }}</strong> 406-285-8250 MT
        </div>
    </div>

    <div class="section__accept-terms">
        <mat-checkbox formControlName="accept">
            Customer accepts the MVD Fee and Service Fee.
        </mat-checkbox>
    </div>

</div>
