import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PaymentSelectionModel } from "Portal/models/payment-selection-model";
import { CardRecord, PayWithCard, RetailPaymentMethodEnum, SubmittingViewStates } from "src/app/storage/retail/retail.state";

@Component({
  selector: "retail-payment-section",
  templateUrl: "./payment-section.component.html",
  styleUrls: ["./payment-section.component.scss"]
})
export class PaymentSectionComponent {

    @Input() summary: PaymentSelectionModel;
    @Input() cardErrorMessage: string;
    @Input() cardRecord: CardRecord;
    @Input() submittingViewState: SubmittingViewStates;

    @Output() cardRecordChange = new EventEmitter<CardRecord>();
    @Output() payWithCreditCard = new EventEmitter<PayWithCard>();
    @Output() payClover = new EventEmitter();

    RetailPaymentMethodEnum = RetailPaymentMethodEnum;
    retailPaymentMethod: RetailPaymentMethodEnum | null = RetailPaymentMethodEnum.CARD_PRESENT;
    SubmittingViewStates = SubmittingViewStates;

}
