<h2>Send Receipt</h2>
<mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Email</mat-label>
    <input matInput
        [(ngModel)]="email"
        email
        required
        #emailInput="ngModel">
</mat-form-field>
<div class="modal-actions">
    <button color="primary"
        mat-stroked-button
        [mat-dialog-close]>
        Cancel
    </button>
    <button color="primary"
        (click)="dialog.close(email)"
        mat-raised-button
        [disabled]="emailInput.invalid">
        Send
    </button>
</div>
