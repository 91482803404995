import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {

    constructor(
        public dialog: MatDialogRef<EmailModalComponent>
    ) {}

    email = "";

    ngOnInit(): void {
    }

}
