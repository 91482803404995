<div class="content-wrap-col">

    <form class="form"
        #formData="ngForm">

        <ng-container *ngIf="cardAndCheckAvailable()">

            <h2>Payment Method</h2>

            <div class="section payment-method">
                <div class="section_title">
                    <h3>Credit Card or Check</h3>
                </div>

                <div class="radio-groups-pay"
                    aria-label="Payment Method">
                    <div class="radio-button-pay">
                        <input type="radio"
                            id="creditCard-pay"
                            name="paymentType"
                            [value]="PaymentMethodEnum.CREDIT_CARD"
                            [(ngModel)]="paymentMethod">
                        <label for="creditCard-pay"
                            aria-label="Credit Card Payment">
                            <i class="fas fa-credit-card"></i>
                            <span>Credit Card</span>
                        </label>
                    </div>
                    <div class="radio-button-pay">
                        <input type="radio"
                            id="check-pay"
                            name="paymentType"
                            [value]="PaymentMethodEnum.CHECK"
                            [(ngModel)]="paymentMethod">
                        <label for="check-pay"
                            aria-label="Check Payment">
                            <i class="fas fa-money-check"></i>
                            <span>Check</span>
                        </label>
                    </div>
                </div>
            </div>
        </ng-container>

        <div *ngIf="payPalAvailable() || applePayAvailable() || googlePayAvailable()"
            class="section pay-card">
            <div class="section_title">
                <h3>Express Checkout</h3>
            </div>

            <div class="pay-actions">
                <ng-container *ngIf="payPalAvailable()">
                    <vitupay-paypal-element
                        [config]="payPalConfig"
                        (onSubmitEvent)="payWithPaymentSystems.emit($event)"
                        (onError)="onError($event)">
                    </vitupay-paypal-element>

                    <vitupay-paypal-element
                        [config]="venmoConfig"
                        (onSubmitEvent)="payWithPaymentSystems.emit($event)"
                        (onError)="onError($event)">
                    </vitupay-paypal-element>
                </ng-container>

                <vitupay-apple-pay-element *ngIf="applePayAvailable()"
                    [config]="applePayConfig"
                    (onSubmitEvent)="payWithPaymentSystems.emit($event)">
                </vitupay-apple-pay-element>

                <vitupay-google-pay-element *ngIf="googlePayAvailable()"
                    [config]="googlePayConfig"
                    (onSubmitEvent)="payWithPaymentSystems.emit($event)">
                </vitupay-google-pay-element>
            </div>
        </div>

        <ng-container *ngIf="paymentMethod == PaymentMethodEnum.CREDIT_CARD">
            <credit-card-form
                #creditCardForm
                [cardErrorMessage]="cardErrorMessage"
                [pakmsKey]="summary.pakmsKey"
                [agencyFeeDescriptor]="summary.agencyFeeDescriptor"
                [serviceFeeDescriptor]="summary.serviceFeeDescriptor"
                [record]="cardRecord"
                (recordChange)="cardRecordChange.emit($event)">
            </credit-card-form>

            <error-message
                [error]="cardErrorMessage">
            </error-message>
        </ng-container>

        <ng-container *ngIf="paymentMethod == PaymentMethodEnum.CHECK">
            <echeck-form
                [stateCodes]="summary.stateCodes"
                [record]="eCheckRecord"
                (recordChange)="eCheckRecordChange.emit($event)">
            </echeck-form>

            <error-message
                [error]="eCheckErrorMessage">
            </error-message>
        </ng-container>

        <div class="form-actions">
            <button *ngIf="summary.failedRedirectUrl"
                class="btn-cancel"
                (click)="cancelPay()"
                mat-stroked-button>
                Cancel
            </button>

            <button *ngIf="paymentMethod == PaymentMethodEnum.CREDIT_CARD"
                class="btn-pay"
                color="primary"
                mat-raised-button
                [disabled]="formData.invalid"
                (click)="payCreditCard()">
                Confirm Payment {{ (summary.due + summary.serviceFeeAmount + summary.nsfFeeAmount) | currency }}
            </button>

            <button *ngIf="paymentMethod == PaymentMethodEnum.CHECK"
                class="btn-pay"
                color="primary"
                mat-raised-button
                [disabled]="formData.invalid"
                (click)="payECheck()">
                Confirm Payment {{ (summary.due + summary.echeckFlatServiceFee + summary.nsfFeeAmount) | currency }}
            </button>
        </div>
    </form>

    <summary
        [paymentMethod]="paymentMethod"
        [summary]="summary">
    </summary>
</div>
