import { createAction, props } from "@ngrx/store";
import { ReceiptState } from "./receipt.state";

const APP_NAME = "RECEIPT";

export const InitializeReceiptAction = createAction(
    `[${APP_NAME}] Initialize Receipt Page`,
    props<{ trackingNumber: string }>(),
);

export const ExtendStateAction = createAction(
    `[${APP_NAME}] Extend State`,
    props<{ newState: Partial<ReceiptState> }>(),
);

export const SendReceiptEmailAction = createAction(
    `[${APP_NAME}] Send Receipt Email`,
    props<{ trackingNumber: string }>(),
);
