/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { EPayApiConfiguration as __Configuration } from '../epay-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateOrderResponse } from '../models/create-order-response';
import { CreatePayPalOrderRequest } from '../models/create-pay-pal-order-request';
import { TransactionResult } from '../models/transaction-result';
import { CapturePayPalOrderRequest } from '../models/capture-pay-pal-order-request';
import { ProcessGooglePayRequest } from '../models/process-google-pay-request';
import { ValidateApplePaySessionRequest } from '../models/validate-apple-pay-session-request';
import { ProcessApplePayRequest } from '../models/process-apple-pay-request';
@Injectable({
  providedIn: 'root',
})
class PaymentService extends __BaseService {
  static readonly paymentCreatePayPalOrderPath = '/Payment/CreatePayPalOrder';
  static readonly paymentCapturePayPalOrderPath = '/Payment/CapturePayPalOrder';
  static readonly paymentCreateGooglePayPaymentPath = '/Payment/CaptureGooglePayOrder';
  static readonly paymentValidateApplePayPath = '/Payment/ValidateApplePay';
  static readonly paymentProcessApplePayPath = '/Payment/ProcessApplePay';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param model undefined
   */
  paymentCreatePayPalOrderResponse(model: CreatePayPalOrderRequest): __Observable<__StrictHttpResponse<CreateOrderResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Payment/CreatePayPalOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateOrderResponse>;
      })
    );
  }
  /**
   * @param model undefined
   */
  paymentCreatePayPalOrder(model: CreatePayPalOrderRequest): __Observable<CreateOrderResponse> {
    return this.paymentCreatePayPalOrderResponse(model).pipe(
      __map(_r => _r.body as CreateOrderResponse)
    );
  }

  /**
   * @param model undefined
   */
  paymentCapturePayPalOrderResponse(model: CapturePayPalOrderRequest): __Observable<__StrictHttpResponse<TransactionResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Payment/CapturePayPalOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransactionResult>;
      })
    );
  }
  /**
   * @param model undefined
   */
  paymentCapturePayPalOrder(model: CapturePayPalOrderRequest): __Observable<TransactionResult> {
    return this.paymentCapturePayPalOrderResponse(model).pipe(
      __map(_r => _r.body as TransactionResult)
    );
  }

  /**
   * @param processRequest undefined
   */
  paymentCreateGooglePayPaymentResponse(processRequest: ProcessGooglePayRequest): __Observable<__StrictHttpResponse<TransactionResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = processRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Payment/CaptureGooglePayOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransactionResult>;
      })
    );
  }
  /**
   * @param processRequest undefined
   */
  paymentCreateGooglePayPayment(processRequest: ProcessGooglePayRequest): __Observable<TransactionResult> {
    return this.paymentCreateGooglePayPaymentResponse(processRequest).pipe(
      __map(_r => _r.body as TransactionResult)
    );
  }

  /**
   * @param model undefined
   */
  paymentValidateApplePayResponse(model: ValidateApplePaySessionRequest): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Payment/ValidateApplePay`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param model undefined
   */
  paymentValidateApplePay(model: ValidateApplePaySessionRequest): __Observable<Blob> {
    return this.paymentValidateApplePayResponse(model).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param processRequest undefined
   */
  paymentProcessApplePayResponse(processRequest: ProcessApplePayRequest): __Observable<__StrictHttpResponse<TransactionResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = processRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Payment/ProcessApplePay`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransactionResult>;
      })
    );
  }
  /**
   * @param processRequest undefined
   */
  paymentProcessApplePay(processRequest: ProcessApplePayRequest): __Observable<TransactionResult> {
    return this.paymentProcessApplePayResponse(processRequest).pipe(
      __map(_r => _r.body as TransactionResult)
    );
  }
}

module PaymentService {
}

export { PaymentService }
