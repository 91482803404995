<div class="summary">
    <div class="summary_header">
        <h3>Summary Info</h3>
    </div>
    <div class="summary_content">
        <table>
            <tbody>
                <tr>
                    <td>Transaction # {{ summary.transactionNumber }} </td>
                    <td>{{ summary.due | currency }}</td>
                </tr>
                <tr *ngIf="summary.hasNsfFeeAmount">
                    <td>NSF Fee: </td>
                    <td>{{ summary.nsfFeeAmount | currency }}</td>
                </tr>
                <tr>
                    <td>{{  paymentMethod == PaymentMethodEnum.CREDIT_CARD ? 'Service Fee 2.3%' : 'Service Fee' }}: </td>
                    <td>{{ (paymentMethod == PaymentMethodEnum.CREDIT_CARD ? summary.serviceFeeAmount : summary.echeckFlatServiceFee) | currency }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>Total Amount</td>
                    <td>{{ (paymentMethod == PaymentMethodEnum.CREDIT_CARD
                            ? (summary.due + summary.serviceFeeAmount + summary.nsfFeeAmount)
                            : (summary.due + summary.echeckFlatServiceFee + summary.nsfFeeAmount)
                        ) | currency }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div class="summary_footer">
        An additional service fee of 2.3% will be added to express checkout and card payments. A $0.25 service fee will be added for check payments.
    </div>
</div>
