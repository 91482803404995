<ng-container *ngIf="(viewState$ | async) == ViewStates.INITIALIZING then initializing"></ng-container>
<ng-container *ngIf="(viewState$ | async) == ViewStates.FORM then form"></ng-container>
<ng-container *ngIf="(viewState$ | async) == ViewStates.NOT_ALLOWED then notAllowed"></ng-container>

<ng-template #form>
    <pay-section
        [summary]="summary$ | async"
        [cardErrorMessage]="cardErrorMessage$ | async"
        [eCheckErrorMessage]="eCheckErrorMessage$ | async"
        [submittingViewState]="submittingViewState$ | async"
        [eCheckRecord]="eCheckRecord$ | async"
        (eCheckRecordChange)="eCheckRecordChange($event)"
        [cardRecord]="cardRecord$ | async"
        (cardRecordChange)="cardRecordChange($event)"
        (payWithCreditCard)="payWithCreditCard($event)"
        (payWithECheck)="payWithECheck()"
        (payWithPaymentSystems)="payWithPaymentSystems($event)"
        (cancelPayment)="cancelPayment('')">
    </pay-section>
</ng-template>

<ng-template #initializing>
    <div *ngIf="(cloverSdkStatus$ | async) == CloverScriptStatus.FAILED_TO_LOAD; else loading"
        class="content content_allowed content_loading">
        <div class="error-message">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.57" d="M20.1191 0C9.52138 0 0.955078 8.94 0.955078 20C0.955078 31.06 9.52138 40 20.1191 40C30.7168 40 39.2831 31.06 39.2831 20C39.2831 8.94 30.7168 0 20.1191 0ZM29.7011 27.18L26.9989 30L20.1191 22.82L13.2392 30L10.5371 27.18L17.4169 20L10.5371 12.82L13.2392 10L20.1191 17.18L26.9989 10L29.7011 12.82L22.8212 20L29.7011 27.18Z" fill="#EA3D2F"/>
            </svg>
            <span>
                Failed to load Clover SDK.<br>
                If the problem persists, please contact customer support
            </span>
        </div>
    </div>
    <ng-template #loading>
        <div class="content content_allowed content_loading">
            <div class="loading-page">
                <div class="animation-load">
                    <div class="animation-load__item"></div>
                    <div class="animation-load__item"></div>
                    <div class="animation-load__item"></div>
                    <div class="animation-load__item"></div>
                    <div class="animation-load__item"></div>
                </div>
                <div class="loading-page__text">
                    Loading ...
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #notAllowed>
    <div class="content content_allowed">
        <h1 class="content-head">Not Allowed</h1>
        <p>Sorry, you don't have an active session or it has expired. Please go back to the Montana website to start the payment process again.</p>
    </div>
</ng-template>
