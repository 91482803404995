<div ngModelGroup="eCheckGroup"
    #eCheckGroup="ngModelGroup">

    <div class="section">
        <div class="section_title">
            <span>1</span>
            <h3>Check Info</h3>
        </div>

        <div class="check-info">
            <mat-radio-group aria-label="Check Info"
                name="isBusinessAccount"
                required
                [(ngModel)]="innerRecord.isBusinessAccount">
                <mat-radio-button [value]="false">Personal</mat-radio-button>
                <mat-radio-button [value]="true">Business</mat-radio-button>
            </mat-radio-group>

            <div class="check-info__img">
                <img *ngIf="!innerRecord.isBusinessAccount"
                    src="../../../assets/images/check-personal.png"
                    height="147"
                    width="260"
                    alt="Check Personal">

                <img *ngIf="innerRecord.isBusinessAccount"
                    src="../../../assets/images/check-business.png"
                    height="148"
                    width="260"
                    alt="Check Business">
            </div>
        </div>

        <div class="full-width">
            <mat-form-field appearance="outline">
                <mat-label>Routing Number</mat-label>
                <input matInput
                    type="text"
                    #routingNumber="ngModel"
                    pattern="^[0-9]{9}$"
                    required
                    name="routingNumber"
                    autocompleteFix
                    (paste)="$event.preventDefault()"
                    (drop)="$event.preventDefault()"
                    [(ngModel)]="innerRecord.routingNumber">
                <mat-error *ngIf="routingNumber.hasError('pattern')">Should be 9 digits</mat-error>
                <mat-error *ngIf="routingNumber.hasError('required')">Field is required</mat-error>
            </mat-form-field>
        </div>

        <div class="full-width">
            <mat-form-field appearance="outline">
                <mat-label>Account Number</mat-label>
                <input matInput
                    type="text"
                    #accountNumber="ngModel"
                    pattern="^[0-9]{4,19}$"
                    name="accountNumber"
                    autocompleteFix
                    (paste)="$event.preventDefault()"
                    (drop)="$event.preventDefault()"
                    [(ngModel)]="innerRecord.accountNumber"
                    required>
                <mat-error *ngIf="accountNumber.hasError('pattern')">Should be 4-19 digits</mat-error>
                <mat-error *ngIf="accountNumber.hasError('required')">Field is required</mat-error>
            </mat-form-field>
        </div>

        <div class="full-width">
            <mat-form-field appearance="outline">
                <mat-label>Confirm Account Number</mat-label>
                <input matInput
                    type="text"
                    #accountNumberConfirmation="ngModel"
                    pattern="^[0-9]{4,19}$"
                    name="accountNumberConfirmation"
                    autocompleteFix
                    (paste)="$event.preventDefault()"
                    (drop)="$event.preventDefault()"
                    [(ngModel)]="innerRecord.accountNumberConfirmation">
                <mat-error *ngIf="accountNumberConfirmation.hasError('mismatch')">Account Number and Confirm Account Number should match</mat-error>
                <mat-error *ngIf="accountNumberConfirmation.hasError('pattern')">Should be 4-19 digits</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="section">
        <div class="section_title">
            <span>2</span>
            <h3>Customer Info</h3>
        </div>

        <ng-container *ngIf="!innerRecord.isBusinessAccount">

            <div class="half-width">
                <mat-form-field appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput
                        type="text"
                        #firstName="ngModel"
                        pattern="^.{1,30}$"
                        required
                        name="firstName"
                        [(ngModel)]="innerRecord.firstName">
                    <mat-error *ngIf="firstName.hasError('pattern')">Should be less than 30 characters</mat-error>
                    <mat-error *ngIf="firstName.hasError('required')">Field is required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput
                        type="text"
                        #lastName="ngModel"
                        pattern="^.{1,30}$"
                        required
                        name="lastName"
                        [(ngModel)]="innerRecord.lastName">
                    <mat-error *ngIf="lastName.hasError('pattern')">Should be less than 30 characters</mat-error>
                    <mat-error *ngIf="lastName.hasError('required')">Field is required</mat-error>
                </mat-form-field>
            </div>

            <div class="half-width">
                <mat-form-field appearance="outline">
                    <mat-label>State Issued ID or Driver License</mat-label>
                    <input matInput
                        type="text"
                        #identifierNumber="ngModel"
                        pattern="^[a-zA-Z0-9]{1,35}$"
                        required
                        name="identifierNumber"
                        autocompleteFix
                        [(ngModel)]="innerRecord.identifierNumber">
                    <mat-error *ngIf="identifierNumber.hasError('pattern')">Should be less than 35 characters</mat-error>
                    <mat-error *ngIf="identifierNumber.hasError('required')">Field is required</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Issuing State</mat-label>
                    <mat-select required
                        name="issuingState"
                        [safariFix]
                        #issuingState="ngModel"
                        [(ngModel)]="innerRecord.issuingState">
                        <mat-option *ngFor="let state of stateCodes"
                            [value]="state.stateCode">
                            {{ state.stateName }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="issuingState.hasError('required')">Field is required</mat-error>
                </mat-form-field>
            </div>

            <div class="full-width">
                <mat-form-field appearance="outline">
                    <mat-label>E-mail</mat-label>
                    <input matInput
                        type="email"
                        name="email"
                        email
                        required
                        #personalEmail="ngModel"
                        [(ngModel)]="innerRecord.email">
                    <mat-error *ngIf="personalEmail.hasError('required')">Field is required</mat-error>
                    <mat-error *ngIf="personalEmail.hasError('email')">Email is not valid</mat-error>
                </mat-form-field>
            </div>
        </ng-container>

        <ng-container *ngIf="innerRecord.isBusinessAccount">

            <div class="full-width">
                <mat-form-field appearance="outline">
                    <mat-label>Business Name</mat-label>
                    <input matInput
                        type="text"
                        #businessName="ngModel"
                        pattern="^.{1,30}$"
                        required
                        name="businessName"
                        [(ngModel)]="innerRecord.businessName">
                    <mat-error *ngIf="businessName.hasError('pattern')">Should be less than 30 characters</mat-error>
                    <mat-error *ngIf="businessName.hasError('required')">Field is required</mat-error>
                </mat-form-field>
            </div>

            <div class="full-width">
                <mat-form-field appearance="outline">
                    <mat-label>Federal Employer Identification Number</mat-label>
                    <input matInput
                        type="text"
                        #identifierNumber="ngModel"
                        pattern="^[0-9]{9}$"
                        required
                        name="identifierNumber"
                        [(ngModel)]="innerRecord.identifierNumber">
                    <mat-error *ngIf="identifierNumber.hasError('pattern')">Should be 9 digits</mat-error>
                    <mat-error *ngIf="identifierNumber.hasError('required')">Field is required</mat-error>
                </mat-form-field>
            </div>

            <div class="full-width">
                <mat-form-field appearance="outline">
                    <mat-label>E-mail</mat-label>
                    <input matInput
                        type="email"
                        name="email"
                        email
                        required
                        #businessEmail="ngModel"
                        [(ngModel)]="innerRecord.email">
                    <mat-error *ngIf="businessEmail.hasError('required')">Field is required</mat-error>
                    <mat-error *ngIf="businessEmail.hasError('email')">Email is not valid</mat-error>
                </mat-form-field>
            </div>
        </ng-container>
    </div>

    <div class="section section-mb-0">
        <div class="section_title">
            <span>3</span>
            <h3>Billing Info</h3>
        </div>

        <div class="half-width">
            <mat-form-field appearance="outline">
                <mat-label>Street Address</mat-label>
                <input matInput
                    type="text"
                    required
                    #street1="ngModel"
                    pattern="^[a-zA-Z0-9\s#'\.,-\/]{1,50}$"
                    name="street1"
                    [(ngModel)]="innerRecord.street1">
                <mat-error *ngIf="street1.hasError('pattern')">Should be less than 50 characters, allowed spaces and #'.,-/</mat-error>
                <mat-error *ngIf="street1.hasError('required')">Field is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Apartment, Suite, Unit etc</mat-label>
                <input matInput
                    type="text"
                    #street2="ngModel"
                    pattern="^[a-zA-Z0-9\s#'\.,-\/]{1,30}$"
                    name="street2"
                    [(ngModel)]="innerRecord.street2">
                <mat-error *ngIf="street2.hasError('pattern')">Should be less than 30 characters, allowed spaces and #'.,-/</mat-error>
            </mat-form-field>
        </div>

        <div class="half-width">
            <mat-form-field appearance="outline">
                <mat-label>City</mat-label>
                <input matInput
                    type="text"
                    #city="ngModel"
                    pattern="^[a-zA-Z\s-]{1,30}$"
                    name="city"
                    required
                    [(ngModel)]="innerRecord.city">
                <mat-error *ngIf="city.hasError('pattern')">Should be less than 30 characters, allowed spaces and hyphens/dashes</mat-error>
                <mat-error *ngIf="city.hasError('required')">Field is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>State</mat-label>
                <mat-select required
                    name="state"
                    #state="ngModel"
                    [safariFix]
                    [(ngModel)]="innerRecord.state">
                    <mat-option *ngFor="let state of stateCodes"
                        [value]="state.stateCode">
                        {{ state.stateName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="state.hasError('required')">Field is required</mat-error>
            </mat-form-field>
        </div>

        <div class="half-width half-width-mb-0">
            <mat-form-field appearance="outline">
                <mat-label>Zip</mat-label>
                <input matInput
                    type="text"
                    #zipCode="ngModel"
                    pattern="^[a-zA-Z0-9\s-]{1,11}$"
                    name="zipCode"
                    required
                    [(ngModel)]="innerRecord.zipCode">
                <mat-error *ngIf="zipCode.hasError('pattern')">Should be less than 11 characters, allowed spaces and hyphens/dashes</mat-error>
                <mat-error *ngIf="zipCode.hasError('required')">Field is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Phone</mat-label>
                <input matInput
                    type="text"
                    #phone="ngModel"
                    pattern="^[0-9]{10}$"
                    [imask]="maskConfig"
                    [unmask]="true"
                    name="phone"
                    required
                    [(ngModel)]="innerRecord.phone">
                <mat-error *ngIf="phone.hasError('pattern')">Should be 10 digits</mat-error>
                <mat-error *ngIf="phone.hasError('required')">Field is required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="section__desc section__desc-transparent">
        <ng-container *ngIf="innerRecord.isBusinessAccount then business else personal"></ng-container>

        <ng-template #business>

            <p>
                Today, being {{ currentDate | date: "mediumDate" }}, by entering the Company's routing and account
                number above and clicking “Pay” as an authorized Company representative, I authorize the
                payment in the amount indicated above to be processed as an electronic funds transfer (EFT) or draft drawn from
                the Company's checking account as indicated above and, if necessary, electronic credits to the
                Company's account to correct erroneous debits. I understand that the processing of the payment will be completed
                within 1 - 2 banking days. If the payment returns unpaid <b>or account not found due to a typo</b>, I authorize you or your service provider to collect the
                payment and the state return item fee and, if applicable, costs, based on the Company's locale that I have provided
                above by EFT(s) or draft(s) drawn from the Company account. I accept these terms, acknowledge these disclosures and
                authorize this payment on behalf of Company and further agree, on Company's behalf, that Company shall be bound by the NACHA
                Rules in effect, both now and as amended from time to time.
            </p>
        </ng-template>

        <ng-template #personal>

            <p>
                Today, being {{ currentDate | date: "mediumDate" }}, I, {{ innerRecord.firstName }} {{
                innerRecord.lastName }}, by entering my routing and account number above and
                clicking “Pay” I authorize my payment in the amount indicated above to be processed as
                an electronic funds transfer (EFT) or draft drawn from my checking account as indicated above
                and, if necessary, to have my account electronically credited to correct erroneous debits. I understand
                that my payment will be processed within 1 - 2 banking days. If the payment returns unpaid
                <b>or account not found due to a typo</b>, I authorize you or your service provider to collect the payment
                and my state's return item fee and,if applicable, costs, by EFT(s) or draft(s) drawn from my account.
            </p>
        </ng-template>

        <div class="section__desc_footer">
            An additional service fee of 2.3% will be added to card payments and $0.25 for check payments
        </div>
    </div>


    <div class="section__accept-terms">
        <mat-checkbox name="accept"
        [(ngModel)]="innerRecord.accept">
            I have read and accept the <a href="/terms" target="_blank">Terms and Conditions</a>, <a href="/priv_policy" target="_blank">Privacy Policy</a>, MVD Fee and Service Fee.
        </mat-checkbox>
    </div>
</div>
