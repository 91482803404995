/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { EPayApiConfiguration as __Configuration } from '../epay-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MotoPaymentSubmitModel } from '../models/moto-payment-submit-model';
import { CreateTransactionModel } from '../models/create-transaction-model';
import { ExpirePaymentUrlRequest } from '../models/expire-payment-url-request';
import { GetPaymentUrlRequest } from '../models/get-payment-url-request';
import { ReceiptDataResponse } from '../models/receipt-data-response';
@Injectable({
  providedIn: 'root',
})
class IntegrationService extends __BaseService {
  static readonly integrationGetPaymentsPath = '/api/v1/payments';
  static readonly integrationGetUncapturedPaymentsPath = '/api/v1/uncapturedpayments';
  static readonly integrationGetCardTokenPath = '/api/v1/cardtoken';
  static readonly integrationGetAbsentTransactionInBackOfficePath = '/api/v1/unprocessedtransactions';
  static readonly integrationGetSequencePath = '/api/v1/sequencenumber';
  static readonly integrationPayPath = '/api/v1/motopay';
  static readonly integrationCreateTransactionPath = '/api/v1/createtransaction';
  static readonly integrationGetTransactionByTrackingNumberPath = '/api/v1/transactionstatus/{trackingNumber}';
  static readonly integrationGetTransactionInformationByTrackingNumberPath = '/api/v1/transactioninformation/{trackingNumber}';
  static readonly integrationGetFailedTransactionsPath = '/api/v1/failedtransactions';
  static readonly integrationExpirePaymentUrlPath = '/api/v1/expirepaymenturl';
  static readonly integrationGetTransactionDetailsPath = '/api/v1/transactionreport';
  static readonly integrationGetPaymentUrlPath = '/api/v1/getpaymenturl';
  static readonly integrationGetReceiptDataPath = '/api/v1/receiptdata/{trackingNumber}';
  static readonly integrationExpireTransactionsPath = '/api/v1/expiretransactions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `IntegrationService.IntegrationGetPaymentsParams` containing the following parameters:
   *
   * - `fromUtc`:
   *
   * - `toUtc`:
   */
  integrationGetPaymentsResponse(params: IntegrationService.IntegrationGetPaymentsParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.fromUtc != null) __params = __params.set('fromUtc', params.fromUtc.toString());
    if (params.toUtc != null) __params = __params.set('toUtc', params.toUtc.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `IntegrationService.IntegrationGetPaymentsParams` containing the following parameters:
   *
   * - `fromUtc`:
   *
   * - `toUtc`:
   */
  integrationGetPayments(params: IntegrationService.IntegrationGetPaymentsParams): __Observable<Blob> {
    return this.integrationGetPaymentsResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `IntegrationService.IntegrationGetUncapturedPaymentsParams` containing the following parameters:
   *
   * - `fromUtc`:
   *
   * - `toUtc`:
   */
  integrationGetUncapturedPaymentsResponse(params: IntegrationService.IntegrationGetUncapturedPaymentsParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.fromUtc != null) __params = __params.set('fromUtc', params.fromUtc.toString());
    if (params.toUtc != null) __params = __params.set('toUtc', params.toUtc.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/uncapturedpayments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `IntegrationService.IntegrationGetUncapturedPaymentsParams` containing the following parameters:
   *
   * - `fromUtc`:
   *
   * - `toUtc`:
   */
  integrationGetUncapturedPayments(params: IntegrationService.IntegrationGetUncapturedPaymentsParams): __Observable<Blob> {
    return this.integrationGetUncapturedPaymentsResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param model undefined
   */
  integrationGetCardTokenResponse(model: MotoPaymentSubmitModel): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/cardtoken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param model undefined
   */
  integrationGetCardToken(model: MotoPaymentSubmitModel): __Observable<Blob> {
    return this.integrationGetCardTokenResponse(model).pipe(
      __map(_r => _r.body as Blob)
    );
  }
  integrationGetAbsentTransactionInBackOfficeResponse(): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/unprocessedtransactions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }  integrationGetAbsentTransactionInBackOffice(): __Observable<Blob> {
    return this.integrationGetAbsentTransactionInBackOfficeResponse().pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param officeType undefined
   */
  integrationGetSequenceResponse(officeType?: 'Echeck' | 'CreditCard' | 'PayPal'): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (officeType != null) __params = __params.set('officeType', officeType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/sequencenumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param officeType undefined
   */
  integrationGetSequence(officeType?: 'Echeck' | 'CreditCard' | 'PayPal'): __Observable<Blob> {
    return this.integrationGetSequenceResponse(officeType).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param model undefined
   */
  integrationPayResponse(model: MotoPaymentSubmitModel): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/motopay`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param model undefined
   */
  integrationPay(model: MotoPaymentSubmitModel): __Observable<Blob> {
    return this.integrationPayResponse(model).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param model undefined
   */
  integrationCreateTransactionResponse(model: CreateTransactionModel): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/createtransaction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param model undefined
   */
  integrationCreateTransaction(model: CreateTransactionModel): __Observable<Blob> {
    return this.integrationCreateTransactionResponse(model).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param trackingNumber undefined
   */
  integrationGetTransactionByTrackingNumberResponse(trackingNumber: null | string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/transactionstatus/${encodeURIComponent(trackingNumber)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param trackingNumber undefined
   */
  integrationGetTransactionByTrackingNumber(trackingNumber: null | string): __Observable<Blob> {
    return this.integrationGetTransactionByTrackingNumberResponse(trackingNumber).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param trackingNumber undefined
   */
  integrationGetTransactionInformationByTrackingNumberResponse(trackingNumber: null | string): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/transactioninformation/${encodeURIComponent(trackingNumber)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param trackingNumber undefined
   */
  integrationGetTransactionInformationByTrackingNumber(trackingNumber: null | string): __Observable<Blob> {
    return this.integrationGetTransactionInformationByTrackingNumberResponse(trackingNumber).pipe(
      __map(_r => _r.body as Blob)
    );
  }
  integrationGetFailedTransactionsResponse(): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/failedtransactions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }  integrationGetFailedTransactions(): __Observable<Blob> {
    return this.integrationGetFailedTransactionsResponse().pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param expirePaymentUrlRequest undefined
   */
  integrationExpirePaymentUrlResponse(expirePaymentUrlRequest: ExpirePaymentUrlRequest): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = expirePaymentUrlRequest;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/expirepaymenturl`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param expirePaymentUrlRequest undefined
   */
  integrationExpirePaymentUrl(expirePaymentUrlRequest: ExpirePaymentUrlRequest): __Observable<boolean> {
    return this.integrationExpirePaymentUrlResponse(expirePaymentUrlRequest).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `IntegrationService.IntegrationGetTransactionDetailsParams` containing the following parameters:
   *
   * - `fromUtc`:
   *
   * - `toUtc`:
   */
  integrationGetTransactionDetailsResponse(params: IntegrationService.IntegrationGetTransactionDetailsParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.fromUtc != null) __params = __params.set('fromUtc', params.fromUtc.toString());
    if (params.toUtc != null) __params = __params.set('toUtc', params.toUtc.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/transactionreport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `IntegrationService.IntegrationGetTransactionDetailsParams` containing the following parameters:
   *
   * - `fromUtc`:
   *
   * - `toUtc`:
   */
  integrationGetTransactionDetails(params: IntegrationService.IntegrationGetTransactionDetailsParams): __Observable<Blob> {
    return this.integrationGetTransactionDetailsResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param requestModel undefined
   */
  integrationGetPaymentUrlResponse(requestModel: GetPaymentUrlRequest): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = requestModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/getpaymenturl`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param requestModel undefined
   */
  integrationGetPaymentUrl(requestModel: GetPaymentUrlRequest): __Observable<Blob> {
    return this.integrationGetPaymentUrlResponse(requestModel).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param trackingNumber undefined
   */
  integrationGetReceiptDataResponse(trackingNumber: string): __Observable<__StrictHttpResponse<ReceiptDataResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/receiptdata/${encodeURIComponent(trackingNumber)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReceiptDataResponse>;
      })
    );
  }
  /**
   * @param trackingNumber undefined
   */
  integrationGetReceiptData(trackingNumber: string): __Observable<ReceiptDataResponse> {
    return this.integrationGetReceiptDataResponse(trackingNumber).pipe(
      __map(_r => _r.body as ReceiptDataResponse)
    );
  }

  /**
   * @param trackingNumbers undefined
   */
  integrationExpireTransactionsResponse(trackingNumbers: Array<string>): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = trackingNumbers;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/expiretransactions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param trackingNumbers undefined
   */
  integrationExpireTransactions(trackingNumbers: Array<string>): __Observable<Blob> {
    return this.integrationExpireTransactionsResponse(trackingNumbers).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module IntegrationService {

  /**
   * Parameters for IntegrationGetPayments
   */
  export interface IntegrationGetPaymentsParams {
    fromUtc?: null | string;
    toUtc?: null | string;
  }

  /**
   * Parameters for IntegrationGetUncapturedPayments
   */
  export interface IntegrationGetUncapturedPaymentsParams {
    fromUtc?: null | string;
    toUtc?: null | string;
  }

  /**
   * Parameters for IntegrationGetTransactionDetails
   */
  export interface IntegrationGetTransactionDetailsParams {
    fromUtc?: null | string;
    toUtc?: null | string;
  }
}

export { IntegrationService }
