<div class="payment-card">
    <div class="payment-card-head">
        <div class="payment-card-head__icon payment-card-head__icon--present">
            <i class="fal fa-credit-card"></i>
            <i class="fal fa-check-circle"></i>
        </div>
        <span>Card Present</span>
        <img src="../../../assets/images/hardware-mini-icon.png"
            class="payment-card-head__img"
            alt="Card Present"
            width="79"
            height="44">
    </div>

    <div class="payment-detail">
        <div class="row">
            <div class="col-4">
                <div class="payment-detail__head">
                    Amount
                </div>
            </div>
            <div class="col-4">
                <div class="payment-detail__head">
                    Service Fee
                </div>
            </div>
            <div class="col-4">
                <div class="payment-detail__head">
                    Payment Type
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="payment-detail__value">
                    {{ summary.total | currency }}
                </div>
            </div>
            <div class="col-4">
                <div class="payment-detail__value">
                    {{ summary.serviceFeeAmount | currency }}
                </div>
            </div>
            <div class="col-4">
                <div class="payment-detail__value">
                    Retail
                </div>
            </div>
        </div>
    </div>

    <div class="payment-action">
        <button color="primary"
            class="btn-action-payment"
            (click)="payClover.emit()"
            mat-raised-button>
            <mat-icon>payment</mat-icon>
            Pay
        </button>
    </div>
</div>
