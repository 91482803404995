<ng-container *ngIf="(receiptState$ | async) as receiptState">

    <div class="content content_allowed">
        <div class="payment-content">

            <ng-container *ngIf="receiptState.viewState == ViewState.LOADING then loading"></ng-container>
            <ng-container *ngIf="receiptState.viewState == ViewState.RECEIPT then receipt"></ng-container>
        </div>
    </div>


    <ng-template #loading>
        <p>loading</p>
    </ng-template>

    <ng-template #receipt>

        <div class="receipt">
            <div class="receipt__block">
                <h2 class="receipt__head">Payment Information</h2>
                <div class="payment-detail">
                    <div class="row">
                        <div class="col-4">
                            <div class="payment-detail__head">
                                Payment Type
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="payment-detail__head">
                                Type
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="payment-detail__head">
                                Account Number
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="payment-detail__head">
                                Name on Account
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="payment-detail__value">
                                {{ receiptState.receipt?.cardEntryType || '-' }}
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="payment-detail__value">
                                {{ receiptState.receipt?.cardType || '-' }}
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="payment-detail__value">
                                **** **** **** {{ receiptState.receipt?.cardLast4 || '****' }}
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="payment-detail__value">
                                {{ receiptState.receipt?.accountHolderName || '-' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="receipt__block">
                <h2 class="receipt__head">Receipt</h2>
                <p class="receipt__desc">Please print this page for your records.</p>

                <div class="receipt__content">
                    <div class="receipt__info">
                        <table class="table-receipt">
                            <tr>
                                <td class="td-head">
                                    Transaction Date:
                                </td>
                                <td>
                                    {{ receiptState.receipt?.transactionCreatedDate | date }}
                                </td>
                            </tr>
                            <tr>
                                <td class="td-head">
                                    Transaction ID:
                                </td>
                                <td>
                                    {{ receiptState.receipt?.transactionId }}
                                </td>
                            </tr>
                        </table>

                        <table class="table-receipt">
                            <tr>
                                <td class="td-head">
                                    Agency Fee:
                                </td>
                                <td>
                                    {{ receiptState.receipt?.agencyFee | currency }}
                                </td>
                            </tr>
                            <tr>
                                <td class="td-head">
                                    Agency Fee Auth:
                                </td>
                                <td>
                                    {{ receiptState.receipt?.agencyFeeAuth || '-' }}
                                </td>
                            </tr>
                            <tfoot>
                                <tr>
                                    <td>
                                        Agency Fee Description:
                                    </td>
                                    <td>
                                        {{ receiptState.receipt?.agencyFeeDescriptor || '-' }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <table *ngIf="receiptState.receipt?.nsfFee" class="table-receipt">
                            <tr>
                                <td class="td-head">
                                    NSF Fee:
                                </td>
                                <td>
                                    {{ receiptState.receipt?.nsfFee | currency }}
                                </td>
                            </tr>
                            <tr>
                                <td class="td-head">
                                    NSF Fee Auth:
                                </td>
                                <td>
                                    {{ receiptState.receipt?.nsfFeeAuth || '-' }}
                                </td>
                            </tr>
                            <tfoot>
                                <tr>
                                    <td>
                                        NSF Fee Description:
                                    </td>
                                    <td>
                                        {{ receiptState.receipt?.nsfFeeDescriptor || '-' }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <table class="table-receipt">
                            <tr>
                                <td class="td-head">
                                    Service Fee:
                                </td>
                                <td>
                                    {{ receiptState.receipt?.serviceFee | currency }}
                                </td>
                            </tr>
                            <tr>
                                <td class="td-head">
                                    Service Fee Auth:
                                </td>
                                <td>
                                    {{ receiptState.receipt?.serviceFeeAuth }}
                                </td>
                            </tr>
                            <tfoot>
                                <tr>
                                    <td>
                                        Service Fee Description:
                                    </td>
                                    <td>
                                        {{ receiptState.receipt?.serviceFeeDescriptor }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <table class="table-receipt table-receipt--total">
                            <tfoot>
                                <tr>
                                    <td>
                                        Total Amount:
                                    </td>
                                    <td>
                                        {{ receiptState.receipt?.totalAmount | currency }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div class="receipt__actions">
                        <button color="primary"
                            class="btn-action-payment"
                            (click)="printReceipt()"
                            mat-raised-button>
                            Print
                        </button>

                        <button color="primary"
                            class="btn-action-payment"
                            (click)="sendReceiptEmail()"
                            mat-raised-button>
                            Email
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>


