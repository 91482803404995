import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";

@Component({
    selector: "app-terms-and-conditions-page",
    templateUrl: "./terms-and-conditions-page.component.html",
    styleUrls: ["./terms-and-conditions-page.component.scss"]
})
export class TermsAndConditionsPageComponent implements OnInit {

    constructor(
        private title: Title,
    ) {}

    ngOnInit(): void {

        this.title.setTitle(`Terms And Conditions - ${environment.appName}`);
    }
}
