import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { PaymentPageComponent } from "./pages/ecom/payment-page/payment-page.component";
import { PrivacyPolicyPageComponent } from "./pages/privacy-policy/privacy-policy-page.component";
import { ReceiptComponent } from "./pages/receipt/receipt.component";
import { PaymentComponent } from "./pages/retail/payment-page/payment.component";
import { TermsAndConditionsPageComponent } from "./pages/terms-and-conditions/terms-and-conditions-page.component";

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: "terms",
                component: TermsAndConditionsPageComponent,
            },
            {
                path: "priv_policy",
                component: PrivacyPolicyPageComponent,
            },
            {
                path: "retail",
                component: PaymentComponent
            },
            {
                path: "receipt/:id",
                component: ReceiptComponent
            },
            {
                path: "**",
                component: PaymentPageComponent,
            }
        ]),
    ],
    exports: [
        RouterModule,
    ]
})
export class AppRoutingModule {}
