import clone from "ramda/es/clone";
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ControlContainer, UntypedFormGroup, NgForm, NgModelGroup } from "@angular/forms";
import { StateCodeModel } from "Portal/models/state-code-model";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ECheckRecord } from "src/app/storage/ecom/ecom.state";

@Component({
    selector: "echeck-form",
    templateUrl: "./echeck-form.component.html",
    styleUrls: ["./echeck-form.component.scss"],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ECheckFormComponent implements AfterViewInit {

    @Input() stateCodes: StateCodeModel[];

    @Input() set record(value: ECheckRecord) { this.innerRecord = clone(value); }
    @Output() recordChange = new EventEmitter<ECheckRecord>();

    @ViewChild("eCheckGroup") eCheckGroup: NgModelGroup;

    innerRecord: ECheckRecord;
    currentDate = new Date();

    maskConfig = {
        mask: "(#00) 000-0000",
        definitions: {
            "#": /[2-9]/
        }
    } as any;

    ngAfterViewInit(): void {

        setTimeout(() => {

            this.eCheckGroup.control.validator = (formGroup: UntypedFormGroup) => {
                const accNumber = formGroup.controls["accountNumber"];
                const accNumberConfirm = formGroup.controls["accountNumberConfirmation"];
                const accept = formGroup.controls["accept"];

                if (!accNumber || !accNumberConfirm) {
                    return null;
                }

                if (accNumberConfirm.errors && !accNumberConfirm.errors.mismatch) {
                    return null;
                }

                accNumberConfirm.setErrors(
                    accNumber.value !== accNumberConfirm.value
                        ? { mismatch: true }
                        : null
                );

                accept.setErrors(
                    !accept.value ? { termsNotAccepted: true } : null
                );
            };

            this.eCheckGroup.valueChanges.pipe(
                distinctUntilChanged(),
                debounceTime(300)
            )
            .subscribe(i => this.recordChange.emit(i));
        });

    }
}
