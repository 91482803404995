import * as JSEncryptModule from "jsencrypt";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { PaymentCreditCardSubmitModel } from "Portal/models/payment-credit-card-submit-model";
import { CheckoutService } from "Portal/services/checkout.service";
import { BehaviorSubject, concat, EMPTY, merge, NEVER, Observable, of, timer } from "rxjs";
import { catchError, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { RiskDeclinedComponent } from "src/app/pages/ecom/risk-declined/risk-declined.component";
import { IStore } from "../store";
import * as EcomActions from "./ecom.actions";
import { environment } from "src/environments/environment";
import {
    CloverScriptStatus,
    DEFAULT_CARD_RECORD,
    DEFAULT_E_CHECK_RECORD,
    ECheckRecord,
    OperationType,
    SubmittingViewStates,
    ViewStates
} from "./ecom.state";
import { selectECheckRecordState, selectSummary } from "./ecom.selectors";
import { PayPalSubmitStatusChangedStatus } from "@vitupay-public/ng-vitupay";

@Injectable()
export class EcomEffects {

    constructor(
        private actions: Actions,
        private store: Store<IStore>,
        private matDialog: MatDialog,
        private checkoutService: CheckoutService,
    ) {}

    initialize$ = createEffect(() => this.actions.pipe(
        ofType(EcomActions.InitializeEcomAction),
        switchMap(() => this.appendCloverSdk()),
        switchMap(sdkStatus => {

            const actions = [
                of(EcomActions.ExtendStateAction({
                    newState: {
                        cloverSdkStatus: sdkStatus,
                    },
                }))
            ];

            if (sdkStatus === CloverScriptStatus.LOADED) {

                const paymentLinkToken = location.pathname.replace("/", "");
                const loadSummary$ = this.checkoutService.checkoutGetCheckoutSummaryModel({ paymentLinkToken }).pipe(
                    switchMap(summary => {

                        const disallowedTransaction = summary.isNotAllowed
                            || summary.isTransactionPaid || summary.sessionExpiredTime == 0;

                        const switchToNotAllowedOnTimeout$ = summary.sessionExpiredTime > 0 && !disallowedTransaction
                            ? timer(summary.sessionExpiredTime * 1000).pipe(
                                map(() => EcomActions.ExtendStateAction({
                                    newState: { viewState: ViewStates.NOT_ALLOWED },
                                }))
                            )
                            : EMPTY;

                        return merge(
                            of(EcomActions.ExtendStateAction({
                                newState: {
                                    summary,
                                    viewState: disallowedTransaction
                                        ? ViewStates.NOT_ALLOWED
                                        : ViewStates.FORM,
                                },
                            })),
                            switchToNotAllowedOnTimeout$,
                        );
                    })
                );

                actions.push(loadSummary$);
            }

            return concat(
                ...actions,
            );
        })
    ));

    payWithCreditCard$ = createEffect(() => this.actions.pipe(
        ofType(EcomActions.PayWithCreditCardAction),
        withLatestFrom(this.store.select(selectSummary)),
        switchMap(([action, summary]) => {

            if (action.data.operation === OperationType.StartPayment) {

                return of({
                    submittingViewState: SubmittingViewStates.SUBMITTING,
                    cardErrorMessage: null
                });
            }

            if (action.data.operation === OperationType.PaymentError) {

                return of({
                    submittingViewState: null,
                    cardErrorMessage: action.data.timeout
                        ? "We are unable to process your payment at this time, please try again later."
                        : null
                });
            }

            if (action.data.operation === OperationType.CheckoutPayment) {

                const params: PaymentCreditCardSubmitModel = {
                    cardFirst6: action.data.cardData.card.first6,
                    cardHolderName: action.data.cardData.cardHolderName,
                    email: action.data.cardData.customerEmail,
                    cardLast4: action.data.cardData.card.last4,
                    paymentToken: action.data.cardData.token,
                    trackingNumber: summary.trackingNumber,
                };

                return this.checkoutService.checkoutPayCreditCard(params).pipe(
                    switchMap(result => {

                        if (result.isSuccess) {

                            setTimeout(() => location.href = result.successRedirectUrl, 3000);
                            return of({ submittingViewState: SubmittingViewStates.SUCCESS });
                        }
                        else {

                            return concat(
                                of({ submittingViewState: null, cardErrorMessage: result.errorMessage, cardRecord: DEFAULT_CARD_RECORD }),
                                result.isRiskDeclined
                                    ? this.matDialog.open(RiskDeclinedComponent, {
                                        panelClass: "risk-declined-modal",
                                        maxWidth: "990px",
                                        autoFocus: false,
                                    }).afterClosed()
                                    : NEVER
                            );
                        }
                    }),
                    catchError((e: HttpErrorResponse) => of({
                        submittingViewState: null,
                        cardErrorMessage: e.error.errorMessage,
                        cardRecord: DEFAULT_CARD_RECORD
                    }))
                );
            }

            else {
                return NEVER;
            }

        }),
        map(newState => EcomActions.ExtendStateAction({ newState })),
    ));

    payWithECheck$ = createEffect(() => this.actions.pipe(
        ofType(EcomActions.PayWithECheckAction),
        withLatestFrom(
            this.store.select(selectSummary),
            this.store.select(selectECheckRecordState),
        ),
        switchMap(([, summary, eCheckRecord]) => {

            const crypt = new JSEncryptModule.JSEncrypt();
            crypt.setPublicKey(summary.rsaPublicKey);

            const params: ECheckRecord = {
                ...eCheckRecord,
                accountNumber: crypt.encrypt(eCheckRecord.accountNumber),
                accountNumberLast4: eCheckRecord.accountNumber.substring(eCheckRecord.accountNumber.length - 4),
                accountNumberConfirmation: null,
                trackingNumber: summary.trackingNumber,
            };

            const request$ = this.checkoutService.checkoutPayEcheck(params).pipe(
                switchMap(result => {

                    if (result.isSuccess) {

                        setTimeout(() => location.href = result.successRedirectUrl, 3000);
                        return of({ submittingViewState: SubmittingViewStates.SUCCESS });
                    }
                    else {

                        return concat(
                            of({
                                submittingViewState: null,
                                eCheckErrorMessage: result.errorMessage,
                                eCheckRecord: DEFAULT_E_CHECK_RECORD
                            }),
                            result.isRiskDeclined
                                ? this.matDialog.open(RiskDeclinedComponent, {
                                    panelClass: "risk-declined-modal",
                                    maxWidth: "990px",
                                    autoFocus: false,
                                }).afterClosed()
                                : NEVER
                        );
                    }
                }),
                catchError((e: HttpErrorResponse) => of({
                    submittingViewState: null,
                    cardErrorMessage: e.error.errorMessage,
                    eCheckRecord: DEFAULT_E_CHECK_RECORD
                }))
            );

            return merge(
                of({ submittingViewState: SubmittingViewStates.SUBMITTING, eCheckErrorMessage: null }),
                request$
            );
        }),
        map(newState => EcomActions.ExtendStateAction({ newState })),
    ));

    payWithPaymentSystems$ = createEffect(() => this.actions.pipe(
        ofType(EcomActions.PayWithPaymentSystems),
        filter(i => i.data.status == PayPalSubmitStatusChangedStatus.Success),
        withLatestFrom(this.store.select(selectSummary)),
        tap(([, summary]) => setTimeout(() => location.href = summary.successRedirectUrl, 3000)),
        map(() => EcomActions.ExtendStateAction({
            newState: {
                submittingViewState: SubmittingViewStates.SUBMITTING,
            }
        })),
    ));

    cancelPayment$ = createEffect(() => this.actions.pipe(
        ofType(EcomActions.CancelPaymentAction),
        withLatestFrom(this.store.select(selectSummary)),
        switchMap(([, summary]) => {
            return this.checkoutService.checkoutCacelPayment(summary.trackingNumber)
        }),
    ));

    appendCloverSdk(): Observable<CloverScriptStatus> {

        const scriptStatusChanged = new BehaviorSubject(CloverScriptStatus.LOADING);
        const scriptEl = document.querySelector(`script[src='${environment.cloverScriptUrl}']`)
            ?? this.createCloverSdkScriptEl();

        scriptEl.addEventListener("load", () => { scriptStatusChanged.next(CloverScriptStatus.LOADED); });
        scriptEl.addEventListener("error", () => { scriptStatusChanged.next(CloverScriptStatus.FAILED_TO_LOAD); });

        return scriptStatusChanged.pipe();
    }

    private createCloverSdkScriptEl(): HTMLElement {

        const scriptEl = document.createElement("script");
        scriptEl.src = environment.cloverScriptUrl;
        document.body.appendChild(scriptEl);

        return scriptEl;
    }
}
