import { ReceiptModel } from "Portal/models/receipt-model";

export enum ViewState {
    LOADING = "LOADING",
    RECEIPT = "RECEIPT",
}

export type ReceiptState = {

    viewState: ViewState;
    receipt?: ReceiptModel;
};

export const createReceiptState = () => {

    return {

        viewState: ViewState.LOADING,
        receipt: {}
    };
};
