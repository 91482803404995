<div *ngIf="error"
    class="error-payment">
    <div class="error-payment__head">
        <i class="fal fa-times-circle"></i>
        <div>Payment Failed!</div>
    </div>

    <div class="error-payment__text">
        {{ error }}
    </div>
</div>
