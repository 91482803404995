<div class="content">
    <h1 class="content-head">Terms and Conditions</h1>
    <p><strong>Last update September 17, 2021</strong></p>

    <p>
        Thank you for using our services (<strong>“Services”</strong>). This Service Agreement
        (<strong>“Agreement”</strong>) is between Motor Vehicle
        Software Corporation d/b/a Vitu (<strong>“Vitu”</strong>) and the person who has clicked on the
        <strong>“Pay”</strong> button (<strong>“you”</strong>, <strong>“your”</strong> or
        <strong>“yours”</strong>), and describes the terms under which Vitu permits you to access and use this service
        to renew a vehicle
        registration (<strong>“Renewal”</strong>) based on fees from Montana Department of Justice Motor Vehicle
        Division Digital
        Government Services (<strong>“MT MVD”</strong>).
    </p>

    <p>
        BY USING THIS WEBSITE OR PURCHASING VITU PRODUCTS OR SERVICES, YOU REPRESENT THAT YOU MAY LEGALLY ENTER INTO
        THIS AGREEMENT, AND THAT YOU AGREE WITH ALL THE TERMS OF THIS AGREEMENT.
    </p>

    <p>
        The following Terms and Conditions are the terms which govern your use of this website, and the offers,
        services, and or products that Vitu, its parents, subsidiaries, or affiliates, collectively herein Vitu offer
        to you on this site (<strong>"Agreement"</strong>). Throughout this site, the terms Vitu, We, Us, and Our refers
        to Vitu. The
        offers on this site, the offer terms, including all information, tools, services, products, available from this
        site, to You, <strong>"the user"</strong>, are conditioned upon your acceptance of all the terms, conditions,
        policies and
        notices stated herein. Your use of the site constitutes your agreement to all such terms, conditions, offer
        terms, policies and notices contained herein in this Agreement.
    </p>

    <p>
        Our support team is at: <a href="mailto:mtsupport@vitu.com">mtsupport@vitu.com</a>.
    </p>

    <ol>
        <li>
            <h2>TERMS AND CONDITIONS</h2>
            <ol>
                <li>
                    <p>
                        You agree to pay for any and all products and services that you purchase on our site and you
                        agree that we may charge your payment method for them.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>SALE OF GOODS AND SERVICES</h2>
            <ol>
                <li>
                    <p>
                        The following goods and services are available on this Site: MT MVD Services. The services will
                        be paid for in full when the services are ordered. All information, descriptions, or images that
                        we provide about our goods and services are as accurate as possible. However, we are not legally
                        bound by such information, descriptions, or images as we cannot guarantee the accuracy of all
                        goods and services we provide. You agree to purchase goods and services from this Site at your
                        own risk. We reserve the right to modify, reject or cancel your order whenever it becomes
                        necessary. If we cancel your order and have already processed your payment, we will give you a
                        refund equal to the amount you paid. You agree that it is your responsibility to monitor your
                        payment instrument to verify receipt of any refund.
                    </p>
                </li>
                <li>
                    <p>
                        We accept the following payment methods on this Site: Credit Card, Debit Card, and Check. When
                        you provide us with your payment information, you authorize our use of and access to the payment
                        instrument you have chosen to use. By providing us with your payment information, you authorize
                        us to charge the amount due to this payment instrument. If we believe your payment has violated
                        any law or these Terms and Conditions, we reserve the right to cancel or reverse your
                        transaction.
                    </p>
                </li>
                <li>
                    <p>
                        Refund requests must be made within 90 days after receipt of your goods. We accept refund
                        requests for goods sold on this Site for any of the following reasons: service or product not
                        received. Refunds do not apply to the following goods: Service or Products received.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>ELECTRONIC CONTACTING AND NOTICES</h2>
            <ol>
                <li>
                    <p>
                        Your affirmative act of registering for a user ID and password, using this site, or purchasing
                        Vitu Products, or using Vitu Services, constitutes your electronic signature to these Terms of
                        Use and your consent to enter into agreements with Vitu electronically. You also agree that we
                        may send to you in electronic form any notices or other communications regarding this site. We
                        can send you electronically any notices or other communications to the email address that you
                        provided to Vitu during registration, or by posting any notices or other communications on this
                        site. The delivery of any notices or other communications from Vitu is effective when sent by
                        us, regardless of whether you read such notice or other communication when you receive it or
                        whether you actually receive the delivery. You can withdraw your consent to receive such notices
                        or other communications electronically by canceling or discontinuing your use of this site.
                    </p>
                </li>
                <li>
                    <p>
                        In order to receive notices or other communications electronically, you must have a personal
                        computer with a modem connected to a communications source (telephone, wireless or broadband),
                        and a Windows-based or Macintosh-based operating system with an Internet browser. You will need
                        a printer attached to your personal computer to print any notices or other communications, if
                        you so choose. All contracts completed electronically will be deemed for all legal purposes to
                        be in writing and legally enforceable as a signed writing.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>ELECTRONIC DELIVERY OF COMMUNICATIONS</h2>
            <ol>
                <li>
                    <p>
                        You agree and consent to receive electronically all Communications provided to you in connection
                        with your MVD Your Way Account and your use of the Services, including but not limited to Vitu.
                        Communications include: (a) agreements and policies you must agree to in order to use the
                        Services (e.g., the MVD Your Way Terms & Conditions and Privacy Policy), including updates to
                        those agreements and policies; (b) payment authorizations and transaction receipts or
                        confirmations; (c) account statements and history; and, (d) all other communications or
                        documents related to or about your account and your use of the Services.
                    </p>
                </li>
                <li>
                    <p>
                        Electronic Communications shall be deemed to be received by you upon delivery in the following
                        manner: (a) posting them to your MVD Your Way account on the MVD Your Way website or in an
                        associated mobile application; (b) posting them on or in a website or mobile application
                        associated with Vitu or the Services; (c) sending them via electronic mail to the email address
                        you used to create your MVD Your Way Account registrations; or, (d) otherwise communicating them
                        to you via the Services.
                    </p>
                </li>
                <li>
                    <p>
                        It is your responsibility to open and review Communications that we deliver to you through the
                        means described above. We may, but are not obligated to under this Policy, provide you with
                        notice of the availability of a Communication that is delivered in one of the methods described
                        above (for example, by informing you of such Communication through a notification sent to your
                        mobile device).
                    </p>
                </li>
                <li>
                    <p>
                        By giving your consent to this Policy, you confirm that you can receive, open, and print or save
                        any Communications referenced in this Policy for your records.
                    </p>
                </li>
                <li>
                    <p>
                        Vitu reserves the right to terminate your use of the Services and the associated products if you
                        decline or withdraw consent to receive electronic Communications. You may contact Vitu in
                        relation to this Policy by using the Contact page in the Policy & Terms page.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>ACCESS COSTS, EQUIPMENT AND SOFTWARE</h2>
            <ol>
                <li>
                    <p>
                        You must provide at your own expense the equipment and Internet connections that you will need
                        to access this site. This site may require the use of certain third-party software. You are
                        responsible for all costs associated with acquiring such software, if any, and complying with
                        any licenses associated with such software. We are not responsible for any costs you incur to
                        access or use this site.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>YOUR USER ID AND PASSWORD</h2>
            <ol>
                <li>
                    <p>
                        You may be asked to create or enter a user ID and password during registration. You agree not to
                        share your user ID and Password with anyone else. You understand that we may suspend or
                        terminate your use of your user ID and Password at any time for any or no reason. If access to
                        your account is suspended or terminated, you acknowledge that you have no right to access this
                        site. Notwithstanding such suspension or termination, the remainder of these Terms of Use shall
                        remain in effect.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>USE OF MATERIAL FROM THIS SITE</h2>
            <ol>
                <li>
                    <p>
                        All contents of this site, including but not limited to any text, software, files, graphics,
                        photos, images, design, music, musical compositions, video, audiovisual works, and data found on
                        this site (collectively, the "Materials"), are the property of and owned by Vitu, its parent
                        companies or its licensors, and are protected by copyright, trademark, and other laws of the
                        United States and other countries. Use of the Materials other than viewing the site, including
                        but not limited to the reproduction, copying, modification, display, adaptation, publication,
                        translation, public performance, reverse engineering, transfer, transmission, broadcast,
                        distribution, licensing, sale, or gift in whole or in part, or the creation of derivative works
                        from such Materials, is expressly prohibited.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>TRADEMARKS AND COPYRIGHTS</h2>
            <ol>
                <li>
                    <p>
                        This site may feature trademarks, service marks, logos, text, software, files, graphics, photos,
                        images, design, music, video, and data that are the property of Vitu and its affiliates or
                        licensors. This site also may include trademarks, service marks, logos, text, software, files,
                        graphics, photos, images, design, music, video, and data of other third parties. All of these
                        trademarks, service marks, logos, text, software, files, graphics, photos, images, design,
                        music, video, and data are the property of their respective owners, and you agree not to use
                        them in any manner without the prior permission of the applicable owner. This site and all of
                        its content are protected under copyright, trademark, and other laws of the United States and
                        other countries.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>DISCLAIMERS</h2>
            <ol>
                <li>
                    <p>
                        Vitu is a privately-owned company. We are not affiliated with, owned or operated by any
                        government agency.
                    </p>
                </li>
                <li>
                    <p>
                        The material contained on this site or in the vehicle report may contain inaccuracies and
                        typographical errors. You agree that we are not liable for content that is provided by other
                        parties. Changes are periodically made to the site and may be made at any time.
                    </p>
                </li>
                <li>
                    <p>
                        YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. THIS SITE AND THE PRODUCTS AND SERVICES PROVIDED
                        THROUGH THIS SITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE RESERVE THE RIGHT TO
                        RESTRICT OR TERMINATE YOUR ACCESS TO THIS SITE OR ANY FEATURE OR PART THEREOF AT ANY TIME. TO
                        THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, VITU EXPRESSLY DISCLAIMS ALL WARRANTIES OF
                        ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND ANY WARRANTIES THAT MATERIALS ON THIS
                        SITE ARE NON-INFRINGING; THAT ACCESS TO THIS SITE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT THIS
                        SITE WILL BE SECURE; THAT THIS SITE OR THE SERVER THAT MAKES THIS SITE AVAILABLE WILL BE
                        VIRUS-FREE; OR THAT INFORMATION ON THIS SITE OR SERVICE WILL BE COMPLETE, ACCURATE, RELIABLE OR
                        TIMELY. IF YOU DOWNLOAD ANY MATERIALS FROM THIS SITE, YOU DO SO AT YOUR OWN DISCRETION AND RISK.
                        YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT
                        RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIALS. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                        WRITTEN, OBTAINED BY YOU FROM VITU OR THROUGH OR FROM THIS SITE SHALL CREATE ANY WARRANTY OF ANY
                        KIND. VITU DOES NOT MAKE ANY WARRANTIES OR REPRESENTATIONS REGARDING THE USE OF THE MATERIALS
                        FROM THIS SITE IN TERMS OF THEIR COMPLETENESS, CORRECTNESS, ACCURACY, ADEQUACY, USEFULNESS,
                        TIMELINESS, RELIABILITY OR OTHERWISE.
                    </p>
                </li>
                <li>
                    <p>
                        IN CERTAIN STATES, THE LAW MAY NOT PERMIT THE DISCLAIMER OF WARRANTIES, SO THE ABOVE DISCLAIMER
                        MAY NOT APPLY TO YOU.
                    </p>
                </li>
                <li>
                    <p>
                        THIS WEBSITE IS A PRIVATELY-OWNED WEBSITE AND IS NEITHER OWNED, OPERATED, OR AFFILIATED WITH ANY
                        GOVERNMENT AGENCY. THERE ARE FEES IMPOSED BY YOUR STATE THAT WILL VARY WITH THE TYPE OF VEHICLE,
                        USE, WEIGHT, AND ARE SET BY YOUR STATE. PLEASE REFER TO OUR TERMS OF USE FOR FURTHER
                        INFORMATION.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>LIMITATION OF LIABILITY</h2>
            <ol>
                <li>
                    <p>
                        TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, YOU UNDERSTAND AND AGREE THAT NEITHER
                        VITU, NOR ANY OF ITS AFFILIATES OR THIRD-PARTY CONTENT PROVIDERS SHALL BE LIABLE FOR ANY DIRECT,
                        INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL, PUNITIVE OR ANY OTHER DAMAGES RELATING
                        TO OR RESULTING FROM YOUR USE OF OR INABILITY TO USE THIS SITE, OUR PRODUCTS AND SERVICES, OR
                        ANY OTHER SITE YOU ACCESS THROUGH A LINK FROM THIS SITE OR FROM ANY ACTIONS WE TAKE OR FAIL TO
                        TAKE AS A RESULT OF EMAIL MESSAGES YOU SEND US. THESE INCLUDE DAMAGES FOR ERRORS, OMISSIONS,
                        INTERRUPTIONS, DEFECTS, DELAYS, COMPUTER VIRUSES, YOUR LOSS OF PROFITS, LOSS OF DATA,
                        UNAUTHORIZED ACCESS TO AND ALTERATION OF YOUR TRANSMISSIONS AND DATA, AND OTHER TANGIBLE AND
                        INTANGIBLE LOSSES.
                    </p>
                </li>
                <li>
                    <p>
                        THE LIMITATION ABOVE APPLIES REGARDLESS OF WHETHER THE DAMAGES ARE CLAIMED UNDER THE TERMS OF A
                        CONTRACT, AS THE RESULT OF NEGLIGENCE OR OTHERWISE ARISE OUT OF OR IN CONNECTION WITH THE USE,
                        INABILITY TO USE, OR PERFORMANCE OF THE INFORMATION, SERVICES, PRODUCTS OR MATERIALS AVAILABLE
                        FROM THIS SITE, AND EVEN IF WE OR OUR REPRESENTATIVES HAVE BEEN NEGLIGENT OR HAVE BEEN ADVISED
                        OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW
                        LONG AN IMPLIED WARRANTY LASTS, OR THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                        INCIDENTAL DAMAGES, ALL OR A PORTION OF THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>INDEMNIFICATION</h2>
            <ol>
                <li>
                    <p>
                        You agree to indemnify, defend and hold harmless Vitu, its affiliates and each of their
                        respective officers, directors, employees, contractors, agents, licensors and suppliers, from
                        and against any and all claims, losses, liabilities, expenses, damages and costs, including
                        reasonable attorneys' fees and court costs, arising out of or resulting from any violation of
                        these Terms of Use. If you cause a technical disruption of this site or the systems transmitting
                        this site to you or others, you agree to be responsible for any and all losses, liabilities,
                        expenses, damages and costs, including reasonable attorneys' fees and court costs, arising or
                        resulting from that disruption.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>JURISDICTION & ENFORCEMENT</h2>
            <ol>
                <li>
                    <p>
                        Any disputes resulting from a visitor’s use of our server(s) or site(s) shall be construed and
                        enforced under the laws of the State of California. Visitors hereby submit to the jurisdiction
                        of the courts of Los Angeles County, California, and waive any objection to venue with respect
                        to actions brought in such courts. In any dispute, the prevailing party shall be entitled to
                        recover its reasonable attorney’s fees and cost from the non-prevailing party. If any provision
                        of these Terms and Conditions is held invalid, illegal, or unenforceable, the validity,
                        legality, or enforceability of the remaining provisions shall in no way be affected or impaired
                        thereby.
                    </p>
                </li>
                <li>
                    <p>
                        Attorney’s Fees and Costs: The parties shall be entitled to recover, in addition to costs,
                        disbursements allowed by law, pre-litigation costs, reasonable attorney’s fees and costs in
                        connection with enforcement of this agreement, and such fees shall be awarded to the prevailing
                        party. Invalidation of any one of the covenants or terms of this Agreement, by judgment of a
                        court, shall not affect any of the other provisions of this Agreement which shall remain in full
                        force and effect.
                    </p>
                </li>
                <li>
                    <p>
                        We recognize that it is possible for you to obtain access to this site from any jurisdiction in
                        the world, but we have no practical ability to prevent such access. This site has been designed
                        to comply with the laws of the State of Montana and of the United States. If any material on
                        this site, or your use of this site, is contrary to the laws of the place where you are when you
                        access it, this site is not intended for you, and you shall not use this site. You are
                        responsible for informing yourself of the laws of your jurisdiction and complying with them.
                    </p>
                </li>
            </ol>
        </li>

        <li>
            <h2>RULES OF CONSTRUCTION</h2>
            <ol>
                <li>
                    <p>
                        Amendment and Modification we reserve the right, in our sole discretion, to change these Terms
                        of Use at any time by posting revised terms on this site. It is your responsibility to check
                        periodically for any changes we may make to these Terms of Use. Your continued use of this site
                        following the posting of changes to these terms or other policies means you accept the changes.
                        No change, amendment, modification, termination or attempted waiver by you of any of the
                        provisions set forth herein shall be binding unless made in writing and signed by a duly
                        authorized representative of Vitu and no representation, promise, inducement or statement of
                        intention has been made by either party which is not embodied herein.
                    </p>
                </li>
                <li>
                    <p>
                        Non-Waiver: Either party’s failure to require the other party’s performance of any term or
                        condition of this Agreement shall not constitute a waiver and shall not affect the right of such
                        party to later enforce such provision, unless such waiver is made expressly in writing signed by
                        an authorized representative of the waiving party. No waiver of any breach of any provision of
                        this Agreement shall constitute a waiver of any prior, concurrent or subsequent breach of the
                        same or any other provisions hereof, and no waiver shall be effective unless made in writing and
                        signed by an authorized representative of the waiving party.
                    </p>
                </li>
                <li>
                    <p>
                        Force Majeure: Neither party shall be liable for service interruptions, delays, failure to
                        perform, damages, losses or destruction, or malfunction of any consequence thereof caused or
                        occasioned by, or due to fire, flood, water, the elements, acts of Good, war, explosions, civil
                        disturbances, governmental actions, shortages of equipment or supplies, unavailability of
                        transportation, acts or omissions of third parties, or any other cause beyond the affected
                        party’s reasonable control. The party so delayed or prevented from performing shall provide
                        prompt notice of such event to the other party and shall exercise good faith efforts to remedy
                        any such cause of delay or cause preventing performance.
                    </p>
                </li>
                <li>
                    <p>
                        Integration: This Agreement shall constitute the entire agreement between the parties with
                        respect to the subject matter hereof and shall be deemed to merge all prior and contemporaneous
                        agreements, communications and understandings (both written and oral).
                    </p>
                </li>
                <li>
                    <p>
                        Severability: If any provision herein is held to be unenforceable, the remaining provisions
                        shall remain in full force and effect. All rights and remedies hereunder are cumulative. Any
                        provision of this instrument prohibited by law in any state shall, as to such state, be
                        ineffective to the extent of such prohibition, without invalidating the remaining provisions of
                        this instrument.
                    </p>
                </li>
                <li>
                    <p>
                        Notice: All notices, requests, demands, and other communications to Vitu hereunder shall be in
                        writing and shall be deemed given at the time such communication is sent by registered or
                        certified mail (return receipt requested), or recognized national overnight courier service, or
                        delivered personally, to the following address (or other address as shall be specified by like
                        notice and made to the attention of both the CEO and General Counsel):
                    </p>
                    <p>
                        <br>
                        Vitu<br>
                        40 West 14th St Suite 4B <br>
                        Helena, MT 59601<br>
                        <a href="tel:+14062858280">406-285-8280</a><br>
                        <a href="mailto:mtsupport@vitu.com">mtsupport@vitu.com</a>
                    </p>
                </li>
                <li>
                    <p>
                        Survival: Any obligations which expressly or by their nature are to continue after termination,
                        cancellation, or expiration of the Agreement shall survive and remain in effect after such
                        happening.
                    </p>
                </li>
            </ol>
        </li>
    </ol>

    <div>&nbsp;</div>
</div>
