import { createSelector } from "@ngrx/store";
import { IStore } from "../store";

export const selectApplicationState = (store: IStore) => store.ecom;

export const selectSummary = createSelector(
    selectApplicationState,
    state => state.summary
);

export const selectCardErrorMessage = createSelector(
    selectApplicationState,
    state => state.cardErrorMessage
);

export const selectECheckErrorMessage = createSelector(
    selectApplicationState,
    state => state.eCheckErrorMessage
);

export const selectViewState = createSelector(
    selectApplicationState,
    state => state.viewState
);

export const selectSubmittingViewState = createSelector(
    selectApplicationState,
    state => state.submittingViewState
);

export const selectECheckRecordState = createSelector(
    selectApplicationState,
    state => state.eCheckRecord
);

export const selectCardRecordState = createSelector(
    selectApplicationState,
    state => state.cardRecord
);

export const selectCloverSdkStatus = createSelector(
    selectApplicationState,
    state => state.cloverSdkStatus
);
