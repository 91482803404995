/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for EPayApi services
 */
@Injectable({
  providedIn: 'root',
})
export class EPayApiConfiguration {
  rootUrl: string = 'https://epay.test.mt.vitupay.com';
}

export interface EPayApiConfigurationInterface {
  rootUrl?: string;
}
