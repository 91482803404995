import { DOCUMENT } from "@angular/common";
import { Directive, Inject, Input, OnInit, OnDestroy } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
    selector: "[safariFix]",
})
export class MatSelectSafariFix implements OnInit, OnDestroy {

    @Input() safariFix: boolean;

    constructor(
        private select: MatSelect,
        @Inject(DOCUMENT) private document: Document
    ) {}

    private destroyed = new Subject();

    ngOnInit() {

        this.select._openedStream
            .pipe(
                takeUntil(this.destroyed)
            )
            .subscribe(() => {

                setTimeout(() => this.document.defaultView.dispatchEvent(new Event("resize")), 150);
            });
    }

    ngOnDestroy() {

        this.destroyed.next(null);
        this.destroyed.complete();
    }
}
