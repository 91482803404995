<div class="payment-option">
    <div class="payment-option__head">Payment Options:</div>
    <div class="payment-option__content">
        <mat-form-field appearance="outline">
            <mat-label>Option</mat-label>
            <mat-select
                name="payment option"
                [(ngModel)]="retailPaymentMethod">
                <mat-option
                    [value]="RetailPaymentMethodEnum.CARD_PRESENT">
                    Card Present
                </mat-option>
                <mat-option
                    [value]="RetailPaymentMethodEnum.CARD_NOT_PRESENT">
                    Card Not Present
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<ng-container *ngIf="retailPaymentMethod == RetailPaymentMethodEnum.CARD_PRESENT">
    <retail-clover
        [summary]="summary"
        (payClover)="payClover.emit()">
    </retail-clover>
</ng-container>

<ng-container *ngIf="retailPaymentMethod == RetailPaymentMethodEnum.CARD_NOT_PRESENT">
    <retail-moto
        [cardErrorMessage]="cardErrorMessage"
        [cardRecord]="cardRecord"
        (cardRecordChange)="cardRecordChange.emit($event)"
        (payWithCreditCard)="payWithCreditCard.emit($event)"
        [summary]="summary">
    </retail-moto>
</ng-container>

<div *ngIf="submittingViewState == SubmittingViewStates.SUBMITTING"
    class="processing-overlay">
    <div class="processing-overlay__progress">
        <div class="animation-load">
            <div class="animation-load__item"></div>
            <div class="animation-load__item"></div>
            <div class="animation-load__item"></div>
            <div class="animation-load__item"></div>
            <div class="animation-load__item"></div>
        </div>
        <span>Payment Processing</span>
    </div>
</div>

<div *ngIf="submittingViewState == SubmittingViewStates.SUCCESS"
    class="processing-overlay">
    <div class="processing-overlay__successfull">
        <i class="fal fa-check-circle"></i>
        <span>Payment Success!!</span>
        <p>
            Please wait a moment we will redirect you to a confirmation page
        </p>
    </div>
</div>
