import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { PaymentSelectionModel } from "Portal/models/payment-selection-model";
import { CardRecord, OperationType, PayWithCard } from "src/app/storage/retail/retail.state";
import { RetailCreditCardFormComponent } from "./credit-card-form/credit-card-form.component";

@Component({
  selector: "retail-moto",
  templateUrl: "./moto.component.html",
  styleUrls: ["./moto.component.scss"]
})
export class MotoComponent {

    @ViewChild("creditCardForm") creditCardForm: RetailCreditCardFormComponent;

    @Input() summary: PaymentSelectionModel;
    @Input() cardErrorMessage: string;
    @Input() cardRecord: CardRecord;

    @Output() cardRecordChange = new EventEmitter<CardRecord>();
    @Output() payWithCreditCard = new EventEmitter<PayWithCard>();

    async payCreditCard(): Promise<void> {

        this.payWithCreditCard.emit({ operation: OperationType.StartPayment });

        const result = await this.creditCardForm.getTokenData();

        const payload: PayWithCard = "error" in result
            ? { operation: OperationType.PaymentError, timeout: result.error == "generationError" }
            : { operation: OperationType.CheckoutPayment, cardData: result };

        this.payWithCreditCard.emit(payload);
    }

}
