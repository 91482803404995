import { PaymentSelectionModel } from "Portal/models/payment-selection-model";
import { ReceiptModel } from "Portal/models/receipt-model";

export enum CloverScriptStatus {

    MISSING_ON_PAGE = "MISSING_ON_PAGE",
    LOADING = "LOADING",
    LOADED = "LOADED",
    FAILED_TO_LOAD = "FAILED_TO_LOAD",
}

export enum CARD_FIELDS {

    CARD_NUMBER = "CARD_NUMBER",
    CARD_DATE = "CARD_DATE",
    CARD_CVV = "CARD_CVV",
    CARD_POSTAL_CODE = "CARD_POSTAL_CODE",
}

export enum RetailViewState {
    CARD = "CARD",
    LOADING = "LOADING",
    NOT_ALLOWED = "NOT_ALLOWED",
}

export enum RetailPaymentMethodEnum {
    CARD_PRESENT = "CARD_PRESENT",
    CARD_NOT_PRESENT = "CARD_NOT_PRESENT",
}

export enum SubmittingViewStates {

    SUBMITTING = "submitting",
    SUCCESS = "success",
}

type ValidationError = {
    touched: boolean,
    error: string,
};

export type CloverResponseError = { [key in keyof any]: string };
export type CloverValidationError = { [key in keyof any]: ValidationError };

export interface CloverTokenResponse {

    card: {
        address_zip: string,
        brand: string,
        exp_month: string,
        exp_year: string,
        first6: string,
        last4: string,
    };
    token: string;
    cardHolderName: string;
    errors: CloverResponseError;
}

export enum OperationType { StartPayment, CheckoutPayment, PaymentError };

export type PayWithCard = { operation: OperationType.StartPayment }
    | { operation: OperationType.CheckoutPayment, cardData: CloverTokenResponse }
    | { operation: OperationType.PaymentError, timeout: boolean };

export interface CardRecord {

    cardHolderName: string;
    accept?: boolean;
}

export type RetailState = {

    submittingViewState: SubmittingViewStates;
    cloverSdkStatus: CloverScriptStatus;
    retailViewState: RetailViewState;
    summary?: PaymentSelectionModel;
    cardErrorMessage: string;
    cardRecord: CardRecord;
};

export const DEFAULT_CARD_RECORD =  { cardHolderName: "" };

export const createRetailState = () => {

    return {

            cloverSdkStatus: null,
            retailViewState: RetailViewState.CARD,
            cardRecord: DEFAULT_CARD_RECORD,
            summary: {},
    };
};
