/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { EPayApiConfiguration as __Configuration } from '../epay-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class RedirectService extends __BaseService {
  static readonly redirectPostPath = '/Redirect';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RedirectService.RedirectPostParams` containing the following parameters:
   *
   * - `VIN`:
   *
   * - `Plate`:
   *
   * - `DlNumber`:
   *
   * - `Amount`:
   *
   * - `SuccessRedirectUrl`:
   *
   * - `FailedRedirectUrl`:
   *
   * - `SecurityToken`:
   *
   * - `UtcTimestamp`:
   *
   * - `TrackingNumber`:
   *
   * - `TransactionUid`:
   *
   * - `AllowEcheck`:
   *
   * - `MiscField1`:
   *
   * - `MiscField2`:
   *
   * - `Source`:
   *
   * - `VersionNumber`:
   *
   * - `AppClientId`:
   *
   * - `OfficeId`:
   *
   * - `RequestId`:
   *
   * - `CustomerName`:
   *
   * - `CustomerEmail`:
   *
   * - `TechId`:
   *
   * - `DishonoredTransactionId`:
   *
   * - `Ttc`:
   *
   * - `NsfFeeAmount`:
   *
   * - `State`:
   *
   * - `AllowedPaymentCode`:
   */
  redirectPostResponse(params: RedirectService.RedirectPostParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.VIN != null) { __formData.append('VIN', params.VIN as string | Blob);}
    if (params.Plate != null) { __formData.append('Plate', params.Plate as string | Blob);}
    if (params.DlNumber != null) { __formData.append('DlNumber', params.DlNumber as string | Blob);}
    if (params.Amount != null) { __formData.append('Amount', JSON.stringify(params.Amount));}
    if (params.SuccessRedirectUrl != null) { __formData.append('SuccessRedirectUrl', params.SuccessRedirectUrl as string | Blob);}
    if (params.FailedRedirectUrl != null) { __formData.append('FailedRedirectUrl', params.FailedRedirectUrl as string | Blob);}
    if (params.SecurityToken != null) { __formData.append('SecurityToken', params.SecurityToken as string | Blob);}
    if (params.UtcTimestamp != null) { __formData.append('UtcTimestamp', JSON.stringify(params.UtcTimestamp));}
    if (params.TrackingNumber != null) { __formData.append('TrackingNumber', params.TrackingNumber as string | Blob);}
    if (params.TransactionUid != null) { __formData.append('TransactionUid', params.TransactionUid as string | Blob);}
    if (params.AllowEcheck != null) { __formData.append('AllowEcheck', JSON.stringify(params.AllowEcheck));}
    if (params.MiscField1 != null) { __formData.append('MiscField1', params.MiscField1 as string | Blob);}
    if (params.MiscField2 != null) { __formData.append('MiscField2', params.MiscField2 as string | Blob);}
    if (params.Source != null) { __formData.append('Source', params.Source as string | Blob);}
    if (params.VersionNumber != null) { __formData.append('VersionNumber', params.VersionNumber as string | Blob);}
    if (params.AppClientId != null) { __formData.append('AppClientId', params.AppClientId as string | Blob);}
    if (params.OfficeId != null) { __formData.append('OfficeId', params.OfficeId as string | Blob);}
    if (params.RequestId != null) { __formData.append('RequestId', params.RequestId as string | Blob);}
    if (params.CustomerName != null) { __formData.append('CustomerName', params.CustomerName as string | Blob);}
    if (params.CustomerEmail != null) { __formData.append('CustomerEmail', params.CustomerEmail as string | Blob);}
    if (params.TechId != null) { __formData.append('TechId', params.TechId as string | Blob);}
    if (params.DishonoredTransactionId != null) { __formData.append('DishonoredTransactionId', JSON.stringify(params.DishonoredTransactionId));}
    if (params.Ttc != null) { __formData.append('Ttc', params.Ttc as string | Blob);}
    if (params.NsfFeeAmount != null) { __formData.append('NsfFeeAmount', JSON.stringify(params.NsfFeeAmount));}
    if (params.State != null) { __formData.append('State', params.State as string | Blob);}
    if (params.AllowedPaymentCode != null) { __formData.append('AllowedPaymentCode', params.AllowedPaymentCode as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Redirect`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `RedirectService.RedirectPostParams` containing the following parameters:
   *
   * - `VIN`:
   *
   * - `Plate`:
   *
   * - `DlNumber`:
   *
   * - `Amount`:
   *
   * - `SuccessRedirectUrl`:
   *
   * - `FailedRedirectUrl`:
   *
   * - `SecurityToken`:
   *
   * - `UtcTimestamp`:
   *
   * - `TrackingNumber`:
   *
   * - `TransactionUid`:
   *
   * - `AllowEcheck`:
   *
   * - `MiscField1`:
   *
   * - `MiscField2`:
   *
   * - `Source`:
   *
   * - `VersionNumber`:
   *
   * - `AppClientId`:
   *
   * - `OfficeId`:
   *
   * - `RequestId`:
   *
   * - `CustomerName`:
   *
   * - `CustomerEmail`:
   *
   * - `TechId`:
   *
   * - `DishonoredTransactionId`:
   *
   * - `Ttc`:
   *
   * - `NsfFeeAmount`:
   *
   * - `State`:
   *
   * - `AllowedPaymentCode`:
   */
  redirectPost(params: RedirectService.RedirectPostParams): __Observable<Blob> {
    return this.redirectPostResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module RedirectService {

  /**
   * Parameters for RedirectPost
   */
  export interface RedirectPostParams {
    VIN?: null | string;
    Plate?: null | string;
    DlNumber?: null | string;
    Amount?: number;
    SuccessRedirectUrl?: null | string;
    FailedRedirectUrl?: null | string;
    SecurityToken?: null | string;
    UtcTimestamp?: number;
    TrackingNumber?: null | string;
    TransactionUid?: string;
    AllowEcheck?: null | boolean;
    MiscField1?: null | string;
    MiscField2?: null | string;
    Source?: 'Unknown' | 'MtDriver' | 'ContactCenter' | 'UnifiedPaymentClient' | 'Retail' | 'NSF';
    VersionNumber?: null | string;
    AppClientId?: null | string;
    OfficeId?: null | string;
    RequestId?: null | string;
    CustomerName?: null | string;
    CustomerEmail?: null | string;
    TechId?: null | string;
    DishonoredTransactionId?: null | number;
    Ttc?: null | string;
    NsfFeeAmount?: null | number;
    State?: 'Pending' | 'Final' | 'Settled' | 'Refunded' | 'Chargeback' | 'Expired' | 'Rejected' | 'Void' | 'Failed' | 'Dishonored';
    AllowedPaymentCode?: null | string;
  }
}

export { RedirectService }
