import { CheckoutSummaryModel } from "Portal/models/checkout-summary-model";
import { PaymentECheckSubmitModel } from "Portal/models/payment-echeck-submit-model";

export enum CloverScriptStatus {

    MISSING_ON_PAGE = "MISSING_ON_PAGE",
    LOADING = "LOADING",
    LOADED = "LOADED",
    FAILED_TO_LOAD = "FAILED_TO_LOAD",
}

export enum CARD_FIELDS {

    CARD_NUMBER = "CARD_NUMBER",
    CARD_DATE = "CARD_DATE",
    CARD_CVV = "CARD_CVV",
    CARD_POSTAL_CODE = "CARD_POSTAL_CODE",
}

export enum ViewStates {

    INITIALIZING = "initializing",
    FORM = "form",
    NOT_ALLOWED = "not_allowed",
}

export enum SubmittingViewStates {

    SUBMITTING = "submitting",
    SUCCESS = "success",
}

export enum PaymentMethodEnum {
    CREDIT_CARD = "CREDIT_CARD",
    CHECK = "CHECK",
}

export enum RetailPaymentMethodEnum {
    CARD_PRESENT = "CARD_PRESENT",
    CARD_NOT_PRESENT = "CARD_NOT_PRESENT",
}


type ValidationError = {
    touched: boolean,
    error: string,
};

export type CloverResponseError = { [key in keyof any]: string };
export type CloverValidationError = { [key in keyof any]: ValidationError };


export interface CloverTokenResponse {

    card: {
        address_zip: string,
        brand: string,
        exp_month: string,
        exp_year: string,
        first6: string,
        last4: string,
    };
    token: string;
    cardHolderName: string;
    customerEmail: string;
    errors: CloverResponseError;
}

export enum OperationType { StartPayment, CheckoutPayment, PaymentError }

export type PayWithCard = { operation: OperationType.StartPayment }
    | { operation: OperationType.CheckoutPayment, cardData: CloverTokenResponse }
    | { operation: OperationType.PaymentError, timeout: boolean }

export interface ECheckRecord extends PaymentECheckSubmitModel {
    accountNumberConfirmation?: string;
    accept?: boolean;
}
export interface CardRecord {

    cardHolderName?: string;
    accept?: boolean;
}

export interface CancelPayment {
    trackingNumber?: string;
}

export type EcomState = {

    cloverSdkStatus: CloverScriptStatus,
    summary: CheckoutSummaryModel;
    viewState: ViewStates;
    submittingViewState: SubmittingViewStates | null;
    cardErrorMessage: string;
    eCheckErrorMessage: string;
    eCheckRecord: ECheckRecord;
    cardRecord: CardRecord;
};

export const DEFAULT_CARD_RECORD =  { cardHolderName: "" };
export const DEFAULT_E_CHECK_RECORD =   {
    isBusinessAccount: false,
    firstName: "",
    lastName: ""
};

export const createEcomState = () => {

    return {
        cloverSdkStatus: CloverScriptStatus.MISSING_ON_PAGE,
        viewState: ViewStates.INITIALIZING,
        cardRecord: DEFAULT_CARD_RECORD,
        eCheckRecord: DEFAULT_E_CHECK_RECORD,
    } as EcomState;
}
