import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { PaymentCreditCardSubmitModel } from "Portal/models/payment-credit-card-submit-model";
import { CheckoutService } from "Portal/services/checkout.service";
import { BehaviorSubject, concat, EMPTY, merge, NEVER, Observable, of, timer } from "rxjs";
import { catchError, filter, map, switchMap, withLatestFrom } from "rxjs/operators";
import { RiskDeclinedComponent } from "src/app/pages/ecom/risk-declined/risk-declined.component";
import { IStore } from "../store";
import * as RetailActions from "src/app/storage/retail/retail.actions";
import { environment } from "src/environments/environment";
import { EmailModalComponent } from "src/app/shared/email-modal/email-modal.component";
import { selectSummary } from "./retail.selectors";
import { CloverScriptStatus, DEFAULT_CARD_RECORD, OperationType, RetailViewState, SubmittingViewStates } from "./retail.state";
import { PaymentSubmitResponse } from "Portal/models/payment-submit-response";

@Injectable()
export class RetailEffects {

    constructor(
        private actions: Actions,
        private store: Store<IStore>,
        private matDialog: MatDialog,
        private checkoutService: CheckoutService,
        private http: HttpClient,
    ) {}

    initializeRetail$ = createEffect(() => this.actions.pipe(
        ofType(RetailActions.InitializeRetailAction),
        switchMap(() => this.appendCloverSdk()),
        switchMap(sdkStatus => {

            const actions = [
                of(RetailActions.ExtendStateAction({
                    newState: {
                        cloverSdkStatus: sdkStatus,
                    },
                }))
            ];

            if (sdkStatus === CloverScriptStatus.LOADED) {

                const trackingNumber = location.search.match(/trackingNumber=(.*)/)[1];

                if (!trackingNumber) {

                    return EMPTY;
                }

                const loadSummary$ = this.checkoutService.checkoutGetPaymentSelectionModel(trackingNumber).pipe(
                    switchMap(summary => {

                        const disallowedTransaction = summary.isNotAllowed
                            || summary.isTransactionPaid;

                        const switchToNotAllowedOnTimeout$ = summary.sessionExpiredTime > 0 && !disallowedTransaction
                            ? timer(summary.sessionExpiredTime * 1000).pipe(
                                map(() => RetailActions.ExtendStateAction({
                                    newState: {
                                        retailViewState: RetailViewState.NOT_ALLOWED
                                    },
                                }))
                            )
                            : EMPTY;

                        return merge(
                            of(RetailActions.ExtendStateAction({
                                newState: {
                                    summary,
                                    retailViewState: disallowedTransaction
                                        ? RetailViewState.NOT_ALLOWED
                                        : RetailViewState.CARD,
                                },
                            })),
                            switchToNotAllowedOnTimeout$,
                        );
                    })
                );

                actions.push(loadSummary$);
            }

            return concat(
                ...actions,
            );
        })
    ));

    payWithCreditCard$ = createEffect(() => this.actions.pipe(
        ofType(RetailActions.PayWithCreditCardAction),
        withLatestFrom(this.store.select(selectSummary)),
        switchMap(([action, summary]) => {

            if (action.data.operation === OperationType.StartPayment) {

                return of({
                    submittingViewState: SubmittingViewStates.SUBMITTING,
                    cardErrorMessage: null
                });
            }

            if (action.data.operation === OperationType.PaymentError) {

                return of({
                    submittingViewState: null,
                    cardErrorMessage: action.data.timeout
                        ? "We are unable to process your payment at this time, please try again later."
                        : null
                });
            }

            if (action.data.operation === OperationType.CheckoutPayment) {

                const params: PaymentCreditCardSubmitModel = {
                    cardFirst6: action.data.cardData.card.first6,
                    cardHolderName: action.data.cardData.cardHolderName,
                    cardLast4: action.data.cardData.card.last4,
                    paymentToken: action.data.cardData.token,
                    trackingNumber: summary.trackingNumber,
                };

                return this.checkoutService.checkoutPayCreditCard(params).pipe(
                    switchMap(result => {

                        if (result.isSuccess) {

                            setTimeout(() => location.href = result.successRedirectUrl, 3000);
                            return of({ submittingViewState: SubmittingViewStates.SUCCESS });
                        }
                        else {

                            return concat(
                                of({ submittingViewState: null, cardErrorMessage: result.errorMessage, cardRecord: DEFAULT_CARD_RECORD }),
                                result.isRiskDeclined
                                    ? this.matDialog.open(RiskDeclinedComponent, {
                                        panelClass: "risk-declined-modal",
                                        maxWidth: "990px",
                                        autoFocus: false,
                                    }).afterClosed()
                                    : NEVER
                            );
                        }
                    }),
                    catchError((e: HttpErrorResponse) => of({
                        submittingViewState: null,
                        cardErrorMessage: e.error.errorMessage,
                        cardRecord: DEFAULT_CARD_RECORD
                    }))
                );
            }

            else {
                return NEVER;
            }

        }),
        map(newState => RetailActions.ExtendStateAction({ newState })),
    ));

    payClover$ = createEffect(() => this.actions.pipe(
        ofType(RetailActions.PayCloverAction),
        withLatestFrom(this.store.select(selectSummary)),
        switchMap(([, summary]) => {

            return this.http.post<PaymentSubmitResponse>(summary.retailPayButtonUrl, summary.retailPaymentData);
        }),
        switchMap(result => {

            if (result.isSuccess) {

                setTimeout(() => location.href = result.successRedirectUrl, 3000);
                return of({ submittingViewState: SubmittingViewStates.SUCCESS });
            }
            else {

                return concat(
                    of({ submittingViewState: null, cardErrorMessage: result.errorMessage, cardRecord: DEFAULT_CARD_RECORD }),
                    result.isRiskDeclined
                        ? this.matDialog.open(RiskDeclinedComponent, {
                            panelClass: "risk-declined-modal",
                            maxWidth: "990px",
                            autoFocus: false,
                        }).afterClosed()
                        : NEVER
                );
            }
        }),
        map(newState => RetailActions.ExtendStateAction({ newState })),
    ));

    appendCloverSdk(): Observable<CloverScriptStatus> {

        const scriptStatusChanged = new BehaviorSubject(CloverScriptStatus.LOADING);
        const scriptEl = document.querySelector(`script[src='${environment.cloverScriptUrl}']`)
            ?? this.createCloverSdkScriptEl();

        scriptEl.addEventListener("load", () => { scriptStatusChanged.next(CloverScriptStatus.LOADED); });
        scriptEl.addEventListener("error", () => { scriptStatusChanged.next(CloverScriptStatus.FAILED_TO_LOAD); });

        return scriptStatusChanged.pipe();
    }

    private createCloverSdkScriptEl(): HTMLElement {

        const scriptEl = document.createElement("script");
        scriptEl.src = environment.cloverScriptUrl;
        document.body.appendChild(scriptEl);

        return scriptEl;
    }
}
