<h2 class="head-modal">
    Error
</h2>

<div class="content-modal">
    <p>
        We are sorry that we cannot complete your transaction. Our decision was based, in part, on information provided by TeleCheck.
    </p>
    <p>
        <strong>What is TeleCheck?</strong>
    </p>
    <p>
        TeleCheck provides payment acceptance, account screening, and fraud prevention
        services to both merchants and financial institutions by reporting on check writing histories. Please visit
        <a href="https://getassistance.telecheck.com/index.html"
        target="_">Get Assistance with Telecheck Services</a> to learn more about TeleCheck.
    </p>
    <p>
        <strong>Why does TeleCheck need personal information about you before we can assist you?</strong> The use of some
        types of personal information, including bank account details and/or a driver’s license number, is needed to verify
        TeleCheck is communicating with the right person about the right records. TeleCheck may even request a social
        security number in order to locate and verify records reported by a bank or credit union. To a consumer worried
        about identity theft, this may seem like a lot to ask. However, TeleCheck only requests what is needed to ensure the
        accuracy of data.
    </p>
    <p>
        <strong>You may have received a decline because of unpaid debt associated with your checking account.</strong>
        TeleCheck will issue a decline alert if it has at least one record in its files of unpaid debt associated with your
        bank account and/or personal information. This type of a decline code is referred to as a “Code 4”. We encourage you
        to contact TeleCheck to learn more details about the information in its records and what you can do to resolve any
        issues related to your receipt of a Code 4 decline.
    </p>
    <p>
        <strong>You may have received a risk-based decline.</strong> TeleCheck analyzes transactions to determine whether
        they appear to be potentially high risk to protect consumers, merchants, and financial institutions from fraud and
        losses. How did TeleCheck determine this “potential risk?” Through the use of modeling tools built on the detection
        of common characteristic groupings observed in past transactions, TeleCheck statistically determines the likelihood
        that a payment may return. So, while there may have been nothing wrong with your transaction, it had certain
        characteristics that made TeleCheck question it. Receiving a risk decline does not signify to you -- or anyone else
        -- that TeleCheck holds records of bad check writing history on you. And, it does not mean you do not have enough
        money in the bank to cover your payment. TeleCheck has no visibility into your bank account balance. And finally, it
        has absolutely no effect on your credit. These risk models provide critical protection against fraud. A Code 3 is
        often the first alert a consumer receives that their account has been fraudulently compromised. Risk modeling also
        enables merchants to continue to safely accept their customers’ checks by minimizing fraud-related losses, and
        controlled loss brings cost benefits to customers.
    </p>
    <p>
        <strong>To learn more:</strong> You may call or write TeleCheck for further information. You will need to provide
        (1) the declined transaction’s record number (if provided), (2) your driver’s license number and its state of
        issuance (3) if applicable, the bank routing and account number you used for the declined transaction, and (4) your
        social security number (if the declined transaction was with a financial institution)
    </p>

    <div class="contact-info">
        <p>
            <strong>Contact information:</strong>
        </p>
        <div>TeleCheck Services, Inc.</div>
        <div>ATTN: Resolutions Department</div>
        <div>P. O. Box 6806</div>
        <div>Hagerstown, MD 21741-6806</div>
        <div>Tel: 1-800-366-2425</div>
        <div>
            <a href="https://getassistance.telecheck.com/index.html" target="_">Get Assistance with Telecheck Services</a>
        </div>
    </div>

    <div class="modal-action">
        <button color="primary"
            mat-raised-button
            [mat-dialog-close]>
            OK
        </button>
    </div>
</div>
