import { ApplicationEffects } from "./application/application.effects";
import { EcomEffects } from "./ecom/ecom.effects";
import { ReceiptEffects } from "./receipt/receipt.effects";
import { RetailEffects } from "./retail/retail.effects";

export const EffectsConfiguration = [
    ApplicationEffects,
    RetailEffects,
    EcomEffects,
    ReceiptEffects,
];
