import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { MaterialModule } from "../material.module";
import { AutocompleteFixDirective } from "./autocomplete-fix/autocomplete-fix.directive";
import { EmailModalComponent } from './email-modal/email-modal.component';
import { ErrorMessageComponent } from "./error-message/error-message.component";
import { VituPayModule } from "@vitupay-public/ng-vitupay";

const exportableComponents = [
    AutocompleteFixDirective,
    ErrorMessageComponent,
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        VituPayModule,
    ],
    declarations: [
        ...exportableComponents,
        EmailModalComponent,
    ],
    exports: [
        ...exportableComponents,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        VituPayModule,
    ]
})
export class SharedModule {}
