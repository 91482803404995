<div class="payment-card">
    <div class="payment-card-head">
        <div class="payment-card-head__icon payment-card-head__icon--not-present">
            <i class="fal fa-credit-card"></i>
            <i class="fal fa-times-circle"></i>
        </div>
        <span>Card Not Present</span>
    </div>

    <div class="payment-detail">
        <div class="row">
            <div class="col-4">
                <div class="payment-detail__head">
                    Amount
                </div>
            </div>
            <div class="col-4">
                <div class="payment-detail__head">
                    Service Fee
                </div>
            </div>
            <div class="col-4">
                <div class="payment-detail__head">
                    Payment Type
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="payment-detail__value">
                    {{ summary.total | currency }}
                </div>
            </div>
            <div class="col-4">
                <div class="payment-detail__value">
                    {{ summary.serviceFeeAmount | currency }}
                </div>
            </div>
            <div class="col-4">
                <div class="payment-detail__value">
                    Retail
                </div>
            </div>
        </div>
    </div>

    <form class="form"
        #formData="ngForm">
        <retail-credit-card-form
            #creditCardForm
            [pakmsKey]="summary.pakmsKey"
            [agencyFeeDescriptor]="''"
            [serviceFeeDescriptor]="''"
            [cardErrorMessage]="cardErrorMessage"
            [record]="cardRecord"
            (recordChange)="cardRecordChange.emit($event)">
        </retail-credit-card-form>
    </form>

    <error-message
        [error]="cardErrorMessage">
    </error-message>

    <div class="payment-action">
        <button color="primary"
            class="btn-action-payment"
            [disabled]="formData.invalid"
            (click)="payCreditCard()"
            mat-raised-button>
            <mat-icon>payment</mat-icon>
            Pay
        </button>
    </div>
</div>
