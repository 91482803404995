import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import * as ReceiptActions from "src/app/storage/receipt/receipt.actions";
import { selectReceiptState } from "src/app/storage/receipt/receipt.selectors";
import { ViewState } from "src/app/storage/receipt/receipt.state";
import { IStore } from "src/app/storage/store";

@Component({
  selector: "receipt",
  templateUrl: "./receipt.component.html",
  styleUrls: ["./receipt.component.scss"]
})
export class ReceiptComponent implements OnInit {

    receiptState$ = this.store.select(selectReceiptState);

    constructor(
        private store: Store<IStore>,
        private activatedRoute: ActivatedRoute,
    ) {}

    trackingNumber: string;
    ViewState = ViewState;

    ngOnInit(): void {

        this.trackingNumber = this.activatedRoute.snapshot.params?.id;
        this.store.dispatch(ReceiptActions.InitializeReceiptAction({ trackingNumber: this.trackingNumber }));
    }

    printReceipt(): void {
        window.print();
    }

    sendReceiptEmail(): void {

        this.store.dispatch(ReceiptActions.SendReceiptEmailAction({ trackingNumber: this.trackingNumber }));
    }

}
