import { Component, Input } from "@angular/core";
import { CheckoutSummaryModel } from "Portal/models/checkout-summary-model";
import { PaymentMethodEnum } from "src/app/storage/ecom/ecom.state";

@Component({
    selector: "summary",
    templateUrl: "./summary.component.html",
    styleUrls: ["./summary.component.scss"]
})
export class SummaryComponent {

    @Input() summary: CheckoutSummaryModel | null = null;
    @Input() paymentMethod: PaymentMethodEnum | null = null;

    PaymentMethodEnum = PaymentMethodEnum;
}
