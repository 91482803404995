import { createAction, props } from "@ngrx/store";
import { CardRecord, PayWithCard, RetailState } from "./retail.state";

const APP_NAME = "RETAIL";

export const InitializeRetailAction = createAction(
    `[${APP_NAME}] Initialize Payment Retail`,
);

export const PayCreditCardAction = createAction(
    `[${APP_NAME}] Pay Credit Card`,
    props<{ data: PayWithCard }>(),
);

export const ExtendStateAction = createAction(
    `[${APP_NAME}] Extend State`,
    props<{ newState: Partial<RetailState> }>(),
);

export const PayWithCreditCardAction = createAction(
    `[${APP_NAME}] Pay With Credit Card`,
    props<{ data: PayWithCard }>(),
);

export const CardRecordChangeAction = createAction(
    `[${APP_NAME}] Card Record Change`,
    props<{ cardRecord: CardRecord }>(),
);

export const PayCloverAction = createAction(
    `[${APP_NAME}] Pay Clover`,
);
