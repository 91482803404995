import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import * as EcomActions from "../../../storage/ecom/ecom.actions";
import * as EcomSelectors from "../../../storage/ecom/ecom.selectors";
import { CardRecord, CloverScriptStatus, ECheckRecord, PayWithCard, ViewStates } from "../../../storage/ecom/ecom.state";
import { IStore } from "../../../storage/store";
import { environment } from "../../../../environments/environment";
import { ApplePaySubmitEvent, GooglePaySubmitEvent, PayPalSubmitEvent } from "@vitupay-public/ng-vitupay";


@Component({
    selector: "app-payment-page",
    templateUrl: "./payment-page.component.html",
    styleUrls: ["./payment-page.component.scss"]
})
export class PaymentPageComponent implements OnInit {

    summary$ = this.store.select(EcomSelectors.selectSummary);
    eCheckErrorMessage$ = this.store.select(EcomSelectors.selectECheckErrorMessage);
    cardErrorMessage$ = this.store.select(EcomSelectors.selectCardErrorMessage);
    submittingViewState$ = this.store.select(EcomSelectors.selectSubmittingViewState);
    viewState$ = this.store.select(EcomSelectors.selectViewState);
    eCheckRecord$ = this.store.select(EcomSelectors.selectECheckRecordState);
    cardRecord$ = this.store.select(EcomSelectors.selectCardRecordState);
    cloverSdkStatus$ = this.store.select(EcomSelectors.selectCloverSdkStatus);

    ViewStates = ViewStates;
    CloverScriptStatus = CloverScriptStatus;

    constructor(
        private store: Store<IStore>,
        private title: Title,
    ) {}

    ngOnInit(): void {

        this.title.setTitle(`Payment - ${environment.appName}`);
        this.store.dispatch(EcomActions.InitializeEcomAction());
    }

    payWithCreditCard(data: PayWithCard): void {

        this.store.dispatch(EcomActions.PayWithCreditCardAction({ data }));
    }

    payWithECheck(): void {

        this.store.dispatch(EcomActions.PayWithECheckAction());
    }

    payWithPaymentSystems(data: PayPalSubmitEvent | ApplePaySubmitEvent | GooglePaySubmitEvent): void {

        this.store.dispatch(EcomActions.PayWithPaymentSystems({ data }));
    }

    cardRecordChange(cardRecord: CardRecord): void {

        this.store.dispatch(EcomActions.CardRecordChangeAction({ cardRecord }));
    }

    eCheckRecordChange(eCheckRecord: ECheckRecord): void {

        this.store.dispatch(EcomActions.ECheckRecordChangeAction({ eCheckRecord }));
    }

    cancelPayment(trackingNumber: string): void{
        
        this.store.dispatch(EcomActions.CancelPaymentAction({trackingNumber}));
    }
}
