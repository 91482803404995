<div class="header">
    <div class="header-wrap">
        <div class="header-wrap__left">
            <div class="header-logo-text">
                <strong>MVD </strong><span>Your Way</span>
            </div>
            <div class="separator"></div>
            <img src="../../assets/images/mvd-logo.png" class="mvd-logo" height="51" width="51" alt="MVD Logo">
            <img src="../../assets/images/mdoj-logo.png" class="mdoj-logo" height="55" width="53" alt="MDOJ Logo">
        </div>
        <div [ngClass]="{'opened': menuVisible}"
            class="header-wrap__right">
            <button (click)="menuVisible = !menuVisible"
                class="mobile-menu-btn">
                <i *ngIf="!menuVisible"
                    class="fal fa-bars"></i>
                <i *ngIf="menuVisible"
                    class="fal fa-times"></i>
            </button>
            <div class="header-nav">
                <a class="header-faq"
                    href="https://mt.vitu.com/mvdyourway/vrguest/faq"
                    target="_blank">
                    <mat-icon>help_outline</mat-icon>
                    <span>FAQ</span>
                </a>
            </div>
        </div>
    </div>
</div>
