<div class="footer">
    <div hidden>Version: 1.0.4.11</div>
    <div class="footer-wrap">
        <div class="footer-container">
            <div class="footer-copyright">
                <div class="footer-logo">
                    <b>MVD</b> Your Way
                </div>
                <img src="../../assets/images/mvd-logo.png" class="mvd-logo" height="51" width="51" alt="MVD Logo">
                <img src="../../assets/images/mdoj-logo.png" class="mdoj-logo" height="55" width="53" alt="MDOJ Logo">
            </div>
            <div class="footer-dev">
                <span>Powered by:</span>
                <img src="../../assets/images/vitu-logo.svg" alt="VITU Logo" class="vitu-logo">
            </div>
        </div>
        <div class="footer-contacts">
            <b>Customer Support by Vitu</b>
            <span class="footer__item address">
                <i class="fal fa-building"></i>
                <span>40 West 14th St Suite 4B, Helena, MT 59601</span>
            </span>
            <span class="footer__item email">
                <i class="fal fa-envelope"></i>
                <a href="mailto:mtsupport@vitu.com">mtsupport@vitu.com</a>
            </span>
            <span class="footer__item phone">
                <i class="fal fa-phone-alt"></i>
                <a href="tel:+14062858280">406-285-8280</a>
            </span>
        </div>
    </div>
</div>
