import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";

@Component({
    selector: "app-privacy-policy-page",
    templateUrl: "./privacy-policy-page.component.html",
    styleUrls: ["./privacy-policy-page.component.scss"]
})
export class PrivacyPolicyPageComponent implements OnInit {

    constructor(
        private title: Title,
    ) {}

    ngOnInit(): void {

        this.title.setTitle(`Privacy Policy - ${environment.appName}`);
    }
}
