import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PaymentSelectionModel } from "Portal/models/payment-selection-model";

@Component({
  selector: "retail-clover",
  templateUrl: "./clover.component.html",
  styleUrls: ["./clover.component.scss"]
})
export class CloverComponent {

    @Input() summary: PaymentSelectionModel;
    @Output() payClover = new EventEmitter();

}
