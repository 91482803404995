import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ApplePaySubmitEvent, GooglePaySubmitEvent, PayPalSubmitEvent } from "@vitupay-public/ng-vitupay";
import { CheckoutSummaryModel } from "Portal/models/checkout-summary-model";
import { PaymentECheckSubmitModel } from "Portal/models/payment-echeck-submit-model";
import { CardRecord, ECheckRecord, PayWithCard, SubmittingViewStates, CancelPayment } from "src/app/storage/ecom/ecom.state";

@Component({
    selector: "pay-section",
    templateUrl: "./pay-section.component.html",
    styleUrls: ["./pay-section.component.scss"]
})
export class PaySectionComponent {

    @Input() summary: CheckoutSummaryModel;
    @Input() submittingViewState: SubmittingViewStates;
    @Input() cardErrorMessage: string;
    @Input() eCheckErrorMessage: string;

    @Input() eCheckRecord: ECheckRecord;
    @Output() eCheckRecordChange = new EventEmitter<ECheckRecord>();

    @Input() cardRecord: CardRecord;
    @Output() cardRecordChange = new EventEmitter<CardRecord>();

    @Output() payWithCreditCard = new EventEmitter<PayWithCard>();
    @Output() payWithECheck = new EventEmitter<PaymentECheckSubmitModel>();
    @Output() payWithPaymentSystems = new EventEmitter<PayPalSubmitEvent | ApplePaySubmitEvent | GooglePaySubmitEvent>();
    @Output() cancelPayment = new EventEmitter<CancelPayment>();

    SubmittingViewStates = SubmittingViewStates;
}
