import { Directive, ElementRef, OnChanges, Input, AfterViewInit } from "@angular/core";
import { getUniqueHashString } from "./get-unique-hashstring";

@Directive({
    selector: "[autocompleteFix]"
})
export class AutocompleteFixDirective implements OnChanges, AfterViewInit {

    @Input() name: string;
    @Input() formControlName: string;

    constructor(
        private elementRef: ElementRef,
    ) {}

    get element() { return this.elementRef.nativeElement as HTMLElement; }

    ngOnChanges() {

        this.setUniqueName();
    }

    ngAfterViewInit() {

        // duplicate call here because ngOnChanges isn't called
        // in case there are no template-provided input values (since Angular v10)
        this.setUniqueName();
        this.setUniqueAutocompleteAttr();
    }

    private setUniqueName() {

        const originalName = this.name
            || this.formControlName
            || this.element.tagName.toLocaleLowerCase();

        this.element.setAttribute("name", originalName + "-" + getUniqueHashString());
    }

    private setUniqueAutocompleteAttr() {

        this.element.setAttribute("autocomplete", `new-${getUniqueHashString()}`);
    }
}
