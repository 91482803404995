export const CLOVER_STYLES = {
    html: {
        height: "auto"
    },
    body: {
        "padding-top": "6px"
    },
    input: {
        "box-sizing": "border-box",
        "caret-color": "#4FC8EC",
        all: "unset",
        "background-color": "#fff",
        "border-radius": "4px",
        color: "#000",
        "font-family": "Roboto, sans-serif",
        "font-size": "16px",
        "font-weight": "400",
        "line-height": "1.5",
        height: "60px",
        width: "100%",
        border: "1px solid #E0E0E0",
        padding: "0 10px"
    },
    "input:hover": {
        "border-color": "#000",
        "border-width": "2px",
        padding: "0 9px"
    },
    "input:focus": {
        "border-color": "#4FC8EC",
        "border-width": "2px",
        color: "#000",
        outline: "0",
        padding: "0 9px"
    },
    "input::placeholder": {
        color: "transparent"
    },
    label: {
        "background-color": "#fff",
        color: "rgba(0, 0, 0, 0.6)",
        "font-family": "'HCo Gotham SSm', Roboto, sans-serif",
        "font-size": "12px",
        "font-stretch": "100%",
        display: "block",
        position: "absolute",
        left: "5px",
        top: "1px",
        padding: "0 5px"
    },
    ".brand": {
        top: "21px",
        right: "15px"
    }
};
