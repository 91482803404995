import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ApplePayElementConfig, ApplePaySubmitEvent, GooglePayElementConfig, GooglePaySubmitEvent, PayPalElementConfig, PayPalInitErrorReason, PayPalOrderProcessIntent, PayPalSubmitEvent } from "@vitupay-public/ng-vitupay";
import { CheckoutSummaryModel } from "Portal/models/checkout-summary-model";
import { PaymentECheckSubmitModel } from "Portal/models/payment-echeck-submit-model";
import { PaymentType } from "Portal/models/payment-type";
import { PaymentService } from "Portal/services/payment.service";
import { CancelPayment, CardRecord, ECheckRecord, OperationType, PaymentMethodEnum, PayWithCard } from "src/app/storage/ecom/ecom.state";
import { CreditCardFormComponent } from "../credit-card-form/credit-card-form.component";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

@Component({
    selector: "payment-form",
    templateUrl: "./payment-form.component.html",
    styleUrls: ["./payment-form.component.scss"]
})
export class PaymentFormComponent implements OnInit {

    @ViewChild("creditCardForm") creditCardForm: CreditCardFormComponent;

    @Input() summary: CheckoutSummaryModel;
    @Input() cardErrorMessage: string;
    @Input() eCheckErrorMessage: string;

    @Output() payWithCreditCard = new EventEmitter<PayWithCard>();
    @Output() payWithECheck = new EventEmitter<PaymentECheckSubmitModel>();
    @Output() payWithPaymentSystems = new EventEmitter<PayPalSubmitEvent | ApplePaySubmitEvent | GooglePaySubmitEvent>();

    @Input() eCheckRecord: ECheckRecord;
    @Output() eCheckRecordChange = new EventEmitter<ECheckRecord>();

    @Input() cardRecord: CardRecord;
    @Output() cardRecordChange = new EventEmitter<CardRecord>();

    @Output() cancelPayment = new EventEmitter<CancelPayment>();

    PaymentMethodEnum = PaymentMethodEnum;
    paymentMethod: PaymentMethodEnum | null = PaymentMethodEnum.CREDIT_CARD;

    amount: number;
    payPalConfig: PayPalElementConfig;
    venmoConfig: PayPalElementConfig;
    applePayConfig: ApplePayElementConfig;
    googlePayConfig: GooglePayElementConfig;

    hubConnection: HubConnection;

    ngOnInit(): void {

        if (this.summary.allowedPaymentTypes.every(i => i != PaymentType.CARD)) {
            this.paymentMethod = PaymentMethodEnum.CHECK;
        }

        this.hubConnection = new HubConnectionBuilder()
        .withUrl(`/TransactionHub?trackingNumber=${this.summary.trackingNumber}`)
        .build();

        this.hubConnection
            .start();

        this.hubConnection.on('expireTransaction', (trackingNumber) => {
            if(this.summary.trackingNumber === trackingNumber) {
                location.href = this.summary.failedRedirectUrl;
            }
        });

        this.amount = this.summary.due + this.summary.serviceFeeAmount + this.summary.nsfFeeAmount;

        this.googlePayConfig = {
            amount: this.summary.due,
            tid: this.summary.rapidConnectTerminalId,
            processUrl: PaymentService.paymentCreateGooglePayPaymentPath,
            processParams: {
                paymentSource: "GooglePay",
                officeId: this.summary.officeId,
                amount: this.summary.due,
                trackingNumber: this.summary.trackingNumber,
                serviceFeeAmount: this.summary.serviceFeeAmount,
            },
            merchant: {
                id: this.summary.googlePayMerchantId,
                name: this.summary.googlePayMerchantName,
            },
            gateway: {
                merchantId: this.summary.googlePayGatewayMerchantId,
            },
            serviceFee: {
                percent: this.summary.serviceFeeRate * 100,
                amount: this.summary.serviceFeeAmount,
            },
            useGooglePayTestEnvironment: !this.summary.isProduction,
            layout: {
                minWidth: 100,
                height: 55,
                paddingLeft: 15,
                paddingRight: 15,
                paddingBottom: 30
            }
        };

        this.applePayConfig = {
            amount: this.summary.due,
            tid: this.summary.rapidConnectTerminalId,
            processUrl: PaymentService.paymentProcessApplePayPath,
            processParams: {
                paymentSource: "ApplePay",
                officeId: this.summary.officeId,
                amount: this.summary.due,
                trackingNumber: this.summary.trackingNumber,
                serviceFeeAmount: this.summary.serviceFeeAmount,
            },
            merchant: {
                appleId: this.summary.applePayId,
                name: this.summary.googlePayMerchantName,
                validateUrl: PaymentService.paymentValidateApplePayPath,
            },
            serviceFee: {
                percent: this.summary.serviceFeeRate * 100,
                amount: this.summary.serviceFeeAmount,
            },
            layout: {
                minWidth: 100,
                height: 55,
                paddingLeft: 15,
                paddingBottom: 30,
                paddingRight: 15
            }
        };

        this.payPalConfig = {
            isProduction: this.summary.isProduction,
            payPalFundingSource: "PAYPAL",
            createOrderUrl: PaymentService.paymentCreatePayPalOrderPath,
            createOrderParams: {
                paymentSource: "PAYPAL",
                officeId: this.summary.officeId,
                amount: this.summary.due,
                trackingNumber: this.summary.trackingNumber,
                serviceFeeAmount: this.summary.serviceFeeAmount,
            },
            captureOrderUrl: PaymentService.paymentCapturePayPalOrderPath,
            captureOrderParams: { trackingNumber: this.summary.trackingNumber },
            processOrderUrl: PaymentService.paymentCapturePayPalOrderPath,
            intent: PayPalOrderProcessIntent.Capture,
            layout: {
                minWidth: 100,
                height: 55,
                paddingLeft: 15,
                paddingRight: 15,
                paddingBottom: 30
            }
        };

        this.venmoConfig = {
            isProduction: this.summary.isProduction,
            payPalFundingSource: "VENMO",
            createOrderUrl: PaymentService.paymentCreatePayPalOrderPath,
            createOrderParams: {
                paymentSource: "VENMO",
                officeId: this.summary.officeId,
                amount: this.summary.due,
                trackingNumber: this.summary.trackingNumber,
                serviceFeeAmount: this.summary.serviceFeeAmount
            },
            captureOrderUrl: PaymentService.paymentCapturePayPalOrderPath,
            captureOrderParams: { trackingNumber: this.summary.trackingNumber },
            processOrderUrl: PaymentService.paymentCapturePayPalOrderPath,
            intent: PayPalOrderProcessIntent.Capture,
            layout: {
                minWidth: 100,
                height: 55,
                paddingLeft: 15,
                paddingRight: 15,
                paddingBottom: 30
            }
        };
    }

    cardAndCheckAvailable() {

        const cardPresent = this.summary.allowedPaymentTypes.some(i => i == PaymentType.CARD);
        const checkPresent = this.summary.allowedPaymentTypes.some(i => i == PaymentType.CHECK);

        return cardPresent && checkPresent;
    }

    applePayAvailable() {

        return this.summary.allowedPaymentTypes.includes("ApplePay");
    }

    googlePayAvailable() {

        return this.summary.allowedPaymentTypes.includes("GooglePay")
            && this.summary.rapidConnectTerminalId
            && this.summary.googlePayGatewayMerchantId
            && this.summary.googlePayMerchantId
            && this.summary.googlePayMerchantName;
    }

    payPalAvailable() {

        return this.summary.allowedPaymentTypes.includes("PayPal");
    }

    onError(error: string): void {

        if (error !== PayPalInitErrorReason.FundingSourceNotEligible) {

            setTimeout(() => location.href = this.summary.failedRedirectUrl, 3000);
        }
    }

    async cancelPay(): Promise<void> {
        await this.cancelPayment.emit({ trackingNumber: this.summary.trackingNumber });
        location.href = this.summary.failedRedirectUrl;
    }

    async payCreditCard(): Promise<void> {

        this.payWithCreditCard.emit({ operation: OperationType.StartPayment });

        const result = await this.creditCardForm.getTokenData();

        const payload: PayWithCard = "error" in result
            ? { operation: OperationType.PaymentError, timeout: result.error == "generationError" }
            : { operation: OperationType.CheckoutPayment, cardData: result };

        this.payWithCreditCard.emit(payload);
    }

    payECheck(): void {

        this.payWithECheck.emit();
    }
}
