import { ActionReducer, Action, RootStoreConfig } from "@ngrx/store";
import { IStore } from "./store";

import { ApplicationReducer } from "./application/application.reducer";
import { RetailReducer } from "./retail/retail.reducer";
import { EcomReducer } from "./ecom/ecom.reducer";
import { ReceiptReducer } from "./receipt/receipt.reducer";

export const ReducerMap: { [key in keyof IStore]: (state: any, action: any) => any } = {
    application: ApplicationReducer,
    ecom: EcomReducer,
    retail: RetailReducer,
    receipt: ReceiptReducer,
};

export const ReducerConfiguration: RootStoreConfig<IStore> = {
    metaReducers: [ClearState],
    runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateSerializability: false,
    }
};

export function ClearState(reducer: ActionReducer<IStore, Action>): ActionReducer<IStore, Action> {

    return (state: IStore | undefined, action: Action) => reducer(state, action);
}
