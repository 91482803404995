import { createReducer, on } from "@ngrx/store";
import * as EcomActions from "./ecom.actions";
import { createEcomState } from "./ecom.state";

export const EcomReducer = createReducer(
    createEcomState(),
    on(EcomActions.CardRecordChangeAction, (state, { cardRecord }) => ({ ...state, cardRecord })),
    on(EcomActions.ECheckRecordChangeAction, (state, { eCheckRecord }) => ({ ...state, eCheckRecord })),
    on(EcomActions.ExtendStateAction, (state, { newState }) => ({ ...state, ...newState })),
);
