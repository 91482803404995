import { createAction, props } from "@ngrx/store";
import { ApplePaySubmitEvent, GooglePaySubmitEvent, PayPalSubmitEvent } from "@vitupay-public/ng-vitupay";
import { CardRecord, ECheckRecord, EcomState, PayWithCard } from "./ecom.state";

const APP_NAME = "ECOM";

export const InitializeEcomAction = createAction(
    `[${APP_NAME}] Initialize Ecom Page`,
);

export const PayWithCreditCardAction = createAction(
    `[${APP_NAME}] Pay With Credit Card`,
    props<{ data: PayWithCard }>(),
);

export const PayWithECheckAction = createAction(
    `[${APP_NAME}] Pay With ECheck`,
);

export const PayWithPaymentSystems = createAction(
    `[${APP_NAME}] Pay With Payment Systems`,
    props<{ data: PayPalSubmitEvent | ApplePaySubmitEvent | GooglePaySubmitEvent }>(),
);

export const CardRecordChangeAction = createAction(
    `[${APP_NAME}] Card Record Change`,
    props<{ cardRecord: CardRecord }>(),
);

export const ECheckRecordChangeAction = createAction(
    `[${APP_NAME}] ECheck Record Change`,
    props<{ eCheckRecord: ECheckRecord }>(),
);

export const ExtendStateAction = createAction(
    `[${APP_NAME}] Extend State`,
    props<{ newState: Partial<EcomState> }>(),
);

export const CancelPaymentAction = createAction(
    `[${APP_NAME}] Cancel Payment`,
    props<{ trackingNumber: string}>(),
);
