/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { EPayApiConfiguration, EPayApiConfigurationInterface } from './epay-api-configuration';

import { CheckoutService } from './services/checkout.service';
import { ErrorService } from './services/error.service';
import { IntegrationService } from './services/integration.service';
import { PaymentService } from './services/payment.service';
import { RedirectService } from './services/redirect.service';
import { TransactionsService } from './services/transactions.service';

/**
 * Provider for all EPayApi services, plus EPayApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    EPayApiConfiguration,
    CheckoutService,
    ErrorService,
    IntegrationService,
    PaymentService,
    RedirectService,
    TransactionsService
  ],
})
export class EPayApiModule {
  static forRoot(customParams: EPayApiConfigurationInterface): ModuleWithProviders<EPayApiModule> {
    return {
      ngModule: EPayApiModule,
      providers: [
        {
          provide: EPayApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
