/* tslint:disable */
type PaymentType =
  'Card' |
  'Cash' |
  'Check' |
  'BundleCredit' |
  'ServiceFee' |
  'CheckServiceFee' |
  'NsfFee' |
  'Paypal' |
  'ApplePay' |
  'GooglePay';
module PaymentType {
  export const CARD: PaymentType = 'Card';
  export const CASH: PaymentType = 'Cash';
  export const CHECK: PaymentType = 'Check';
  export const BUNDLE_CREDIT: PaymentType = 'BundleCredit';
  export const SERVICE_FEE: PaymentType = 'ServiceFee';
  export const CHECK_SERVICE_FEE: PaymentType = 'CheckServiceFee';
  export const NSF_FEE: PaymentType = 'NsfFee';
  export const PAYPAL: PaymentType = 'Paypal';
  export const APPLE_PAY: PaymentType = 'ApplePay';
  export const GOOGLE_PAY: PaymentType = 'GooglePay';
  export function values(): PaymentType[] {
    return [
      CARD,
      CASH,
      CHECK,
      BUNDLE_CREDIT,
      SERVICE_FEE,
      CHECK_SERVICE_FEE,
      NSF_FEE,
      PAYPAL,
      APPLE_PAY,
      GOOGLE_PAY
    ];
  }
}

export { PaymentType }